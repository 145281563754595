import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Popup from '@EditorComponents/Popup'
import ThemesForm from '@SiteComponents/ThemesForm'
import showConfetti from '@Utils/ShowConfettiAnimation'

import {
  ITheme,
  ISetThemePayload,
} from '@SiteModules/Theme/types'

import {
  setTheme,
  fetchThemes,
} from '@SiteModules/Theme/actions'

import './SetThemePopup.scss'

const SHORT_TIMESPAN = 2000 // ms

interface SetThemePopupProps {
  readonly themes: ITheme[]
  readonly isNewProject: boolean
  readonly isReadyForTheme: boolean
  readonly projectVersionIds: string[]
  readonly subtitleLanguages: string[]
  readonly loadingThemes: boolean
  closePopup(): void
  setTheme(payload: ISetThemePayload): void
  fetchThemes(): void
}

interface SetThemePopupState {}

class SetThemePopup extends React.Component<SetThemePopupProps, SetThemePopupState> {
  componentDidMount() {
    this.props.fetchThemes()
  }

  close = () => {
    const { isNewProject, projectVersionIds } = this.props
    const isForOneProjectVersion = projectVersionIds.length === 1

    this.props.closePopup()
    showConfetti()

    setTimeout(() => {
      window.location.href = (isForOneProjectVersion && isNewProject) ? `/processing?projectVersionId=${projectVersionIds[0]}` : '/'
    }, SHORT_TIMESPAN)
  }

  onSubmit = (themeId: number) => {
    const {
      projectVersionIds,
      subtitleLanguages,
      isNewProject,
    } = this.props

    this.props.setTheme({
      themeId,
      projectVersionIds,
      subtitleLanguages,
      isNewProject,
    })
  }

  render = () => {
    const { themes, isReadyForTheme, loadingThemes } = this.props

    return  (
      <Popup
        className="SetThemePopup"
        closePopup={this.close}
      >
        <section className="SetThemePopup__themes-form-wrapper">
          <ThemesForm
            themes={themes}
            isReady={isReadyForTheme && !loadingThemes}
            onSubmit={this.onSubmit}
            isRenderedInEditor={false}
          />
        </section>
      </Popup>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { themes, projectVersionSettings } = state

  return {
    themes: themes.themes,
    isReadyForTheme: projectVersionSettings.isReadyForTheme,
    loadingThemes: themes.loadingThemes,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    setTheme,
    fetchThemes,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SetThemePopup)
