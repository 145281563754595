import React, { useEffect, useState } from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import Checkbox from '@EditorComponents/Checkbox'
import { Link } from 'react-router-dom'
import { IUser, IPasswordable, Submittable } from '@Root/types'
import { useForm } from 'react-hook-form'
// import GoogleButton from '@Images/google_signin_light.png'
import GoogleLogo from '@Images/google-logo.svg'
import getParamValueFromUrl from '@Utils/GetParamValueFromUrl'

import './SignUpForm.scss'


interface TermsAndConditionsInput {
  readonly termsAndConditions: boolean
}

interface SignUpFormStep1Props extends Submittable {
}

type FormData = Pick<IUser, 'email'> & Pick<IPasswordable, 'password'> & TermsAndConditionsInput

const SignUpFormStep1: React.FC<SignUpFormStep1Props> = props => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>()

  const [emailFromUrl, setEmailFromUrl] = useState('')

  useEffect(() => {
    const url = window.location.href
    const email = getParamValueFromUrl(url, 'email')
    if (email) { setEmailFromUrl(decodeURIComponent(email)) }
  })

  // tslint:disable-next-line:no-shadowed-variable
  const onSubmit = handleSubmit(({ email, password }) => {
    props.submit(email, password)
  })

  const forwardToGoogle = () => {
    window.location.href = '/users/auth/google_oauth2'
  }

  const renderHeader = () => {
    return(
      <div className="SignUpForm__header">
        Make your video accessible to everyone
      </div>
    )
  }

  const renderOauth = () => {
    // TO KEEP IN CASE OF REVIEW
    // return(
    //   <div className="SignInForm__oauth">
    //     <img src={GoogleButton} className="SignUpForm__oauth-button SignUpForm__oauth-button-google" onClick={forwardToGoogle}/>
    //   </div>
    // )
    return(
      <div className="SignUpForm__submit-button-wrapper">
        <ButtonNewV2
          caption="Sign in with Google"
          size="large"
          styleType="colorful-white"
          icon={<GoogleLogo/>}
          onClick={forwardToGoogle}
        />
      </div>
    )
  }

  const renderSeparator = () => {
    return(
      <div className="SignUpForm__delimiter">
        <hr />
        <span>OR</span>
        <hr />
      </div>
    )
  }

  const renderEmail = () => {
    return(
      <div className="SignUpForm__formfield">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          defaultValue={emailFromUrl}
          {...register('email', {
            required: true,
            maxLength: 100,
            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,63})+$/ // https://www.w3resource.com/javascript/form/email-validation.php. Changed to \w{1,63} to allow all possible TLD (see http://data.iana.org/TLD/tlds-alpha-by-domain.txt)
          })}
        />
        {
          errors.email &&
          errors.email.type === 'required' &&
          <span className="errorMessage">&#x26A0; This field is required</span>
        }
        {
          errors.email &&
          errors.email.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
        {
          errors.email &&
          errors.email.type === 'pattern' &&
          <span className="errorMessage">&#x26A0; This must be a valid email address</span>
        }
      </div>
    )
  }

  const renderPassword = () => {
    return(
      <div className="SignUpForm__formfield">
        <label htmlFor="password">
          Create password
          <span className="SignUpForm__label-password-min">
            6 character min
          </span>
        </label>
        <input
          id="password"
          type="password"
          {...register('password', {
            required: true,
            minLength: 6,
            maxLength: 100
          })}
        />
        {
          errors.password &&
          errors.password.type === 'required' &&
          <span className="errorMessage">&#x26A0; This field is required</span>
        }
        {
          errors.password &&
          errors.password.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
        {
          errors.password &&
          errors.password.type === 'minLength' &&
          <span  className="errorMessage">&#x26A0; Password must be at least 6 characters long</span>
        }
      </div>
    )
  }

  const renderTermsAndConditions = () => {
    return(
      <div className="SignUpForm__terms-wrapper">
        <div className="SignUpForm__terms">
          <Checkbox
            id="termsAndConditions"
            registerReturn={register('termsAndConditions', {
              required: true
            })}
            defaultChecked={false}
            label={(
              <div>
                I have read and accept the general <a href="https://links.checksub.com/CGV" target="_blank" rel="noopener noreferrer">terms & conditions</a>
              </div>
            )}
          />
        </div>
        {
          errors.termsAndConditions &&
          errors.termsAndConditions.type === 'required' &&
          <span className="errorMessage">&#x26A0; You must read and accept our terms and conditions</span>
        }
      </div>
    )
  }

  const renderSubmitButton = () => {
    return(
      <div className="SignUpForm__submit-button-wrapper">
        <ButtonNewV2
          type="submit"
          caption="Next"
          styleType="brand-primary"
          size="large"
          disabled={props.disableButton}
          showSpinner={props.showSpinner}
        />
      </div>
    )
  }

  const renderLinks = () => {
    return(
      <div className="SignUpForm__links">
        Already have an account? <Link to="/users/sign_in">Login here</Link>
      </div>
    )
  }

  return(
    <div className="SignUpForm" >
      {renderLinks()}
      <form className="SignUpForm__main" onSubmit={onSubmit} >
        {renderHeader()}
        <div className="SignUpForm__main-formField-wrapper">
          {renderOauth()}
          {renderSeparator()}
          {renderEmail()}
          {renderPassword()}
          {renderTermsAndConditions()}
          {renderSubmitButton()}
        </div>
      </form>
    </div>
  )
}

export default SignUpFormStep1
