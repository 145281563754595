import React, { useState } from 'react'
import Balloon from '@SiteComponents/Balloon'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import Transcription from '@Images/transcription_icon.svg'
import capitalize from 'lodash/capitalize'
import range from 'lodash/range'
import classnames from 'classnames'
import More from '@Images/more_icon.svg'
import Star from '@Images/star.svg'
import RatingStar from '@Images/star_rating_small.svg'
import RatingStarActive from '@Images/star_rating_active_small.svg'
import formatDateAndTime from '@Utils/FormatDateAndTime'
import useClickOutsideListener from '@Utils/UseClickOutsideListener'
import zipAndSaveFiles from '@Utils/ZipAndSaveFiles'
import toastr from 'toastr'
import 'toastr/build/toastr.css'

import {
  ISubtitle
} from './types'

interface SubtitleProps {
  readonly projectVersionId: string
  readonly subtitle: ISubtitle
  readonly isDisabled: boolean
  readonly hasCurrentSubscription: boolean
  readonly projectVersionIsShortened: boolean
  openImportSrtPopup(subtitleId: number): void
  openExternalTokenPopup(subtitleId: number): void
  openExportationPopup(subtitleId: number): void
  openRateSubtitlePopup(subtitleId: number): void
}

const Subtitle: React.FC<SubtitleProps> = props => {
  const [openBalloon, setOpenBalloon] = useState<boolean>(false)
  const { subtitle, isDisabled, hasCurrentSubscription } = props
  const [showInfoForDisabled, setShowInfoForDisabled] = useState<boolean>(false)
  const [showInfoForProcessing, setShowInfoForProcessing] = useState<boolean>(false)
  const [showInfoForRating, setShowInfoForRating] = useState<boolean>(false)
  const [infoForDisabledTimeout, setInfoForDisabledTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)
  const [infoForProcessingTimeout, setInfoForProcessingTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)
  const [infoForRatingTimeout, setInfoForRatingTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  useClickOutsideListener(`Subtitles__more-icon-wrapper-${props.subtitle.id}`, setOpenBalloon, openBalloon)

  const toggleShowingBalloon = () => {
    setOpenBalloon(!openBalloon)
  }

  const toggleShowInfoForDisabled = () => {
    if (infoForDisabledTimeout) {
      setShowInfoForDisabled(false)
      clearTimeout(infoForDisabledTimeout)
      setInfoForDisabledTimeout(null)
    } else {
      const newInfoForDisabledTimeout = setTimeout(() => {
        setShowInfoForDisabled(true)
      }, window.Checksub.tooltipDelay)
      setInfoForDisabledTimeout(newInfoForDisabledTimeout)
    }
  }

  const toggleShowInfoForProcessing = () => {
    if (infoForProcessingTimeout) {
      setShowInfoForProcessing(false)
      clearTimeout(infoForProcessingTimeout)
      setInfoForProcessingTimeout(null)
    } else {
      const newInfoForProcessingTimeout = setTimeout(() => {
        setShowInfoForProcessing(true)
      }, window.Checksub.tooltipDelay)
      setInfoForProcessingTimeout(newInfoForProcessingTimeout)
    }
  }

  const toggleShowInfoForRating = () => {
    if (infoForRatingTimeout) {
      setShowInfoForRating(false)
      clearTimeout(infoForRatingTimeout)
      setInfoForRatingTimeout(null)
    } else {
      const newInfoForRatingTimeout = setTimeout(() => {
        setShowInfoForRating(true)
      }, window.Checksub.tooltipDelay)
      setInfoForRatingTimeout(newInfoForRatingTimeout)
    }
  }

  const openExternalTokenPopup = () => {
    setOpenBalloon(false)
    props.openExternalTokenPopup(parseInt(subtitle.id, 10))
  }

  const openImportSrtPopup = () => {
    setOpenBalloon(false)
    props.openImportSrtPopup(parseInt(subtitle.id, 10))
  }

  const handleClickOnExport = () => {
    setOpenBalloon(false)

    const { projectId, projectVersionId } = subtitle.attributes
    const belongsToTtsProject = subtitle.attributes.belongsToTtsProject
    const shouldForwardToEditor = subtitle.attributes.category === 'free' || (subtitle.attributes.category === 'subscription' && !hasCurrentSubscription) || belongsToTtsProject

    if (shouldForwardToEditor) {
      const editorUrl = `/projects/${projectId}/project_versions/${projectVersionId}/subtitles/${subtitle.id}/edit?exportation=true`
      window.location.href =  belongsToTtsProject ? `${editorUrl}&openVoice=true` : editorUrl
    } else {
      props.openExportationPopup(parseInt(subtitle.id, 10))
    }
  }

  const { category, stage, numberOfStars } = subtitle.attributes
  const ratableSubtitle = category !== 'manual' && category !== 'onboarding' && stage === 'done'
  const alreadyRated = numberOfStars !== -1

  const openRateSubtitlePopup = () => {
    if (alreadyRated) { return }

    props.openRateSubtitlePopup(parseInt(subtitle.id, 10))
  }

  const downloadAsZipFile = () => {
    if (!subtitle.attributes.exportationUrls) { return }

    toastr.info('Download in preparation Keep this page open to get the file once it is ready', '')

    const exportationUrls = Object.values(subtitle.attributes.exportationUrls)
    zipAndSaveFiles(exportationUrls)
  }

  const renderStars = () => {
    return (
      range(1, 6).map(rating => {
        return (
          rating <= numberOfStars ? (
            <RatingStarActive key={rating} className="Subtitles__rate-star"/>
          ) : (
            <RatingStar key={rating} className="Subtitles__rate-star"/>
          )
        )
      })
    )
  }

  const subtitleStage = capitalize(subtitle.attributes.stage.replace('_', ' '))
  const isProcessed =
    subtitleStage === 'New' ||
    subtitleStage === 'Done' ||
    subtitleStage === 'Waiting customer validation' ||
    subtitleStage === 'Processed' ||
    subtitleStage === 'Ready'||
    subtitleStage === 'Claim'

  const isUnpaid = subtitleStage === 'Unpaid'

  const allowLinkToEditorOnly =
    subtitle.attributes.waiting_for_completion_of_transcript &&
    subtitle.attributes.stage !== 'separating_audio' &&
    subtitle.attributes.stage !== 'generating_diarization'

  const processingStep = subtitle.attributes.processing_step || ''

  const isProcessing = !!processingStep

  const openEditor = () => {
    const { projectId, projectVersionId, waiting_for_completion_of_transcript, hasVoice } = subtitle.attributes
    const showEditorWithInfoText = waiting_for_completion_of_transcript

    if (isProcessed || showEditorWithInfoText || isProcessing) {
      const urlParams = hasVoice ? '?openVoice=true' : ''
      window.location.href = `/projects/${projectId}/project_versions/${projectVersionId}/subtitles/${subtitle.id}/edit${urlParams}`
    }
  }

  const shouldRenderIcon = subtitle.attributes.transcription
  const shouldShowImportSrt = !subtitle.attributes.belongsToTtsProject && !props.projectVersionIsShortened

  return(
    <tr key={subtitle.id} className="Subtitles__table-row" >
      <td className="ProjectVersions__table-cell Subtitles__spacer-1" />
      <td className="ProjectVersions__table-cell Subtitles__language">
        {!isDisabled && (isProcessed || allowLinkToEditorOnly) ? (
          <p className="link" onClick={openEditor}>
            <span className="Subtitles__language-name">
              {subtitle.attributes.language}
            </span>
            {shouldRenderIcon &&
              <span className="ProjectVersions__table-transcription-icon">
                <Transcription />
              </span>
            }
          </p>
        ) : (
          <p
            onMouseEnter={toggleShowInfoForDisabled}
            onMouseLeave={toggleShowInfoForDisabled}
          >
            <span className="Subtitles__language-name">
              {subtitle.attributes.language}
            </span>
            {shouldRenderIcon &&
              <span className="ProjectVersions__table-transcription-icon">
                <Transcription />
              </span>
            }
            {showInfoForDisabled && isDisabled &&
              <span className="ProjectVersionsList__tooltip Subtitles__disabled-tooltip">
                Free projects are accessible for 30 days only
              </span>
            }
          </p>
        )}
      </td>
      <td className="ProjectVersions__table-cell Subtitles__status" onMouseEnter={toggleShowInfoForProcessing} onMouseLeave={toggleShowInfoForProcessing}>
      {!isUnpaid && (
        <span className={`Subtitles__status-badge ${subtitle.attributes.stage} ${processingStep.split(' ').join('')}`}>
          <React.Fragment>
            {subtitleStage}{isProcessing && <span> {processingStep}/3</span>}
            {isProcessing && showInfoForProcessing &&
              <div className="ProjectVersionsList__tooltip Subtitles__processing-tooltip">
                The processing duration is generally equal to half the video length. <br/>
                You will receive an email once it’s done. ✉️
              </div>
            }
          </React.Fragment>
        </span>
      )}
      </td>
      <td className="ProjectVersions__table-cell Subtitles__type">
        {subtitle.attributes.type}
      </td>
      <td className="ProjectVersions__table-cell Subtitles__creation-date">
        {subtitle.attributes.created_at}
      </td>
      <td className="ProjectVersions__table-cell Subtitles__last-export">
        {subtitle.attributes.exportationFileUrl && (
          <a href={subtitle.attributes.exportationFileUrl}>
            Your file
          </a>
        )}
        {subtitle.attributes.exportationUrls && (
          <p className="Subtitles__download-link" onClick={downloadAsZipFile}>
            Your files
          </p>
        )}
      </td>
      <td className="ProjectVersions__table-cell Subtitles__export-date">
        {subtitle.attributes.exportedAt && formatDateAndTime(subtitle.attributes.exportedAt)}
      </td>
      <td className="ProjectVersions__table-cell Subtitles__actions">
        <div className="Subtitles__actions-wrapper">
          {!isDisabled && (isProcessed || allowLinkToEditorOnly) && (
            <ButtonNewV2
              size="small"
              styleType="brand-primary"
              caption="Edit"
              onClick={openEditor}
            />
          )}
          {ratableSubtitle && (
            <div className={classnames('Subtitles__rating-icon', {
              'Subtitles__rating-icon-inactive': alreadyRated
              })}
              onMouseEnter={toggleShowInfoForRating}
              onMouseLeave={toggleShowInfoForRating}
              onClick={openRateSubtitlePopup}
            >
              <Star />
              {showInfoForRating && (
                <div className="ProjectVersionsList__tooltip Subtitles__rating-tooltip">
                  {alreadyRated ? (
                    <span className="Subtitles__rating-tooltip-score">
                      Quality score {renderStars()}
                    </span>
                  ) : (
                    <span>
                      Evaluate the subtitle quality
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {!isDisabled && (isProcessed && !allowLinkToEditorOnly) && (
            <div
              className={`Subtitles__more-icon-wrapper Subtitles__more-icon-wrapper-${props.subtitle.id} ${openBalloon ? 'Subtitles__more-icon-wrapper-active':''}`}
              onClick={toggleShowingBalloon}
            >
              <div className="Subtitles__more-icon">
                <More />
              </div>
              {openBalloon && (
                <div className="Subtitles__balloon-wrapper">
                  <Balloon>
                    <li className="Balloon__list__item" onClick={handleClickOnExport}>
                      Export
                    </li>
                    <li className="Balloon__list__item" onClick={openExternalTokenPopup}>
                      Share
                    </li>
                    {shouldShowImportSrt && (
                      <li className="Balloon__list__item" onClick={openImportSrtPopup}>
                        Import SRT
                      </li>
                    )}
                  </Balloon>
                </div>
              )}
            </div>
          )}
        </div>
      </td>
      <td className="ProjectVersions__table-cell Subtitles__spacer-2" />
    </tr>
  )
}

export default Subtitle
