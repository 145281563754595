import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'

import NoMatch from '@SitePages/NoMatch'
import Dashboard from '@SitePages/Dashboard'
import Vocabularies from '@SitePages/Vocabularies'
import Guidelines from '@SitePages/Guidelines'
import Termbases from '@SitePages/Termbases'
import UsersSignUp from '@SitePages/UsersSignUp'
import UsersSignIn from '@SitePages/UsersSignIn'
import Survey from '@SitePages/Onboarding/Survey'
import Subscription from '@SitePages/Subscription'
import UsersPasswordNew from '@SitePages/UsersPasswordNew'
import UsersPasswordEdit from '@SitePages/UsersPasswordEdit'
import UsersConfirmationNew from '@SitePages/UsersConfirmationNew'
import CheckEmail from '@SitePages/Onboarding/CheckEmail'
import ChannelsNew from '@SitePages/ChannelsNew'
import ChannelsManage from '@SitePages/ChannelsManage'
import ProjectVersionSettings from '@SitePages/ProjectVersionSettings'
import ProjectVersionSettingsTts from '@SitePages/ProjectVersionSettingsTts'
import Upload from '@SitePages/Upload'
import ProjectTypeSelection from '@SitePages/ProjectTypeSelection'
import DemoUpload from '@SitePages/DemoUpload'
import DemoProjectVersionSettings from '@SitePages/DemoProjectVersionSettings'
import Processing from '@SitePages/Processing'

const router = createBrowserRouter(
  [
    {
      element: <PublicRoute />,
      children: [
        {
          path: '/demo/upload',
          element: <DemoUpload />
        },
        {
          path: '/demo/setup',
          element: <DemoProjectVersionSettings />
        },
        {
          path: '/users/sign_up',
          element: <UsersSignUp />
        },
        {
          path: '/users/sign_in',
          element: <UsersSignIn />
        },
        {
          path: '/users/confirmation/new',
          element: <UsersConfirmationNew />
        },
        {
          path: '/users/password/new',
          element: <UsersPasswordNew />
        },
        {
          path: '/users/password/edit',
          element: <UsersPasswordEdit />
        }
      ]
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: '/',
          element: <Dashboard />
        },
        {
          path: '/subscriptions',
          element: <Subscription />
        },
        {
          path: '/channels/new',
          element: <ChannelsNew />
        },
        {
          path: '/channels/manage',
          element: <ChannelsManage />
        },
        {
          path: '/customizations/vocabularies',
          element: <Vocabularies />
        },
        {
          path: '/customizations/guidelines',
          element: <Guidelines />
        },
        {
          path: '/customizations/termbases',
          element: <Termbases />
        },
        {
          path: '/project_type_selection',
          element: <ProjectTypeSelection />
        },
        {
          path: '/upload',
          element: <Upload />
        },
        {
          path: '/setup',
          element: <ProjectVersionSettings />
        },
        {
          path: '/setup_tts',
          element: <ProjectVersionSettingsTts />
        },
        {
          path: '/processing',
          element: <Processing />
        },
        {
          path: '/onboarding',
          element: <Survey />
        },
        {
          path: '/onboarding/check_email',
          element: <CheckEmail />
        }
      ]
    },
    {
      path: '*',
      element: <NoMatch />
    }
  ]
)

export default router
