import React from 'react'
import formattedCreditsWithHours from '@Utils/FormattedCreditsWithHours'
import classnames from 'classnames'

import './Costs.scss'

interface CostsProps {
  readonly totalCost: number
  readonly availableTotalCredits: number
  readonly openedForMultipleProjects?: boolean
  readonly shouldHideCosts: boolean
}

const Costs: React.FC<CostsProps> = props => {
  const {
    totalCost,
    availableTotalCredits,
    openedForMultipleProjects,
    shouldHideCosts,
  } = props

  const hasEnoughTotalCredits = totalCost <= availableTotalCredits

  const formattedTotalCost: string = formattedCreditsWithHours(totalCost)
  const renderCosts = () => {
    return (
      <span>You will use {formattedTotalCost} of your credits.
        <a href="https://support.checksub.com/help/faq/how-credits-work" target="_blank">Understand why.</a>
      </span>
    )
  }

  const renderMissingCredits = () => {
    const formattedMissingCredits: string = formattedCreditsWithHours(totalCost - availableTotalCredits)

    return (
      <span>
        You are {formattedMissingCredits} short of credits to create {openedForMultipleProjects ? 'these projects' : 'this project'}.
      </span>
    )
  }

  return (
    <div>
      <div
        className={classnames(
          'Costs',
          {'Costs__hidden': shouldHideCosts})
        }
      >
        {hasEnoughTotalCredits ? renderCosts() : renderMissingCredits()}
      </div>
    </div>
  )
}

export default Costs
