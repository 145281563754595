import React from 'react'

import './NoMatch.scss'

interface NoMatchProps {}

const NoMatch: React.FC<NoMatchProps> = _props => {
  return(
    <h1>404 page, oops...</h1>
  )
}

export default NoMatch
