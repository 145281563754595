import { IActionSuccess, IAction } from '@Root/types'

export const ThemeActionTypes = {
  SET_THEME: '@@theme/SET_THEME',
  FETCH_THEMES: '@@theme/FETCH_THEMES',
  FETCH_THEMES_SUCCESS: '@@theme/FETCH_THEMES_SUCCESS',
  FETCH_THEMES_FAILURE: '@@theme/FETCH_THEMES_FAILURE',
  OPEN_SET_THEME_POPUP: '@@theme/OPEN_SET_THEME_POPUP',
  CLOSE_SET_THEME_POPUP: '@@theme/CLOSE_SET_THEME_POPUP',
}

export interface IThemeState {
  readonly themes: ITheme[]
  readonly setThemePopupOpen: boolean
  readonly loadingThemes: boolean
}

export interface ITheme {
  readonly id: number
  readonly attributes: IThemeAttributes
}

export interface IThemeAttributes {
  readonly name: string
  readonly sampleUrl?: string
  readonly sampleImageUrl?: string
}

export interface ISetThemePayload {
  readonly themeId: number
  readonly isNewProject: boolean
  readonly projectVersionIds: string[]
  readonly subtitleLanguages: string[]
}

export interface IThemeData {
  readonly id: string
  readonly type: 'theme'
  readonly attributes: IThemeAttributes
}

export interface IFetchThemesSuccessResponseBody {
  readonly data: IThemeData[]
}

export type TThemeActionTypes = IAction<ISetThemePayload> | IActionSuccess<IFetchThemesSuccessResponseBody>
