import { IAction } from '@Root/types'
import {
  IVoiceAttributes,
} from '@EditorContainers/Settings/types'

export const ProjectVersionSettingsTtsActionTypes = {
  FETCH_PROJECT_VERSION_SETTINGS_TTS: '@@projectVersionSettings/FETCH_PROJECT_VERSION_SETTINGS_TTS',
  FETCH_PROJECT_VERSION_SETTINGS_TTS_SUCCESS: '@@projectVersionSettings/FETCH_PROJECT_VERSION_SETTINGS_TTS_SUCCESS',
  FETCH_PROJECT_VERSION_SETTINGS_TTS_FAILURE: '@@projectVersionSettings/FETCH_PROJECT_VERSION_SETTINGS_TTS_FAILURE',
  SETUP_TTS: '@@projectVersionSettings/SETUP_TTS',
  SETUP_TTS_SUCCESS: '@@projectVersionSettings/SETUP_TTS_SUCCESS',
  SETUP_TTS_FAILURE: '@@projectVersionSettings/SETUP_TTS_FAILURE',
}

export interface IProjectVersionSettingsTtsState {
  readonly loading: boolean
  readonly dubbingLanguages: string[]
  readonly dubbingLanguagesCodes: string[]
  readonly voices: IVoiceAttributes[]
  readonly subscriptionAllowsVoice: boolean
  readonly availableTotalCredits: number
}

export interface IFetchProjectVersionSettingsTtsSuccessResponsePayload {
  readonly dubbingLanguages: string[]
  readonly dubbingLanguagesCodes: string[]
  readonly voices: IVoiceAttributes[]
  readonly subscription: {
    readonly allowsVoice: boolean
    readonly availableTotalCredits: number
  }
}

export interface ISetupTtsPayload {
  readonly projectVersionLanguage: string
  readonly voiceName: string
  readonly scriptText?: string
  readonly termbaseName?: string
}

export interface ISetupTtsSuccessResponsePayload {
  readonly editorUrl: string
}

export type IProjectVersionSettingsTtsActionTypes =
  IAction<IFetchProjectVersionSettingsTtsSuccessResponsePayload>
