import React from 'react'
import classnames from 'classnames'

import './IconButton.scss'

interface IconButtonProps {
  readonly caption: string
  readonly disabled?: boolean
  readonly type?: string
  readonly children?: React.ReactNode
  onClick?(): void
}

const IconButton: React.FC<IconButtonProps> = props => {
  return (
    <button
      className={classnames('IconButton', {
        'IconButton__border': props.type === 'border',
      })}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
      <span className="IconButton__caption">
        {props.caption}
      </span>
    </button>
  )
}

export default IconButton
