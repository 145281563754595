import React, { useState, useRef } from 'react'
import srtParser2 from 'srt-parser-2'
import AddVideosImage from '@Images/add_videos_true_scale.svg'
import Subtitle from '@Images/subtitle.svg'
import File from '@Images/file.svg'
import Checkbox from './Checkbox'

interface ScriptImportProps {
  readonly hasSrt: boolean
  readonly speakerDiarizationChecked: boolean
  setTranscriptText(newTranscriptText: string): void
  setHasSrt(newHasSrt: boolean): void
  setSpeakerDiarizationChecked(newSpeakerDiarizationChecked: boolean): void
}

const MAX_SRT_SIZE = 2097152 // Bytes = 2MB

const ScriptImport: React.FC<ScriptImportProps> = props => {
  const { hasSrt, speakerDiarizationChecked } = props

  const [dragEnterCounter, setDragEnterCounter] = useState(0)

  const fileRef = useRef<HTMLInputElement>(null)
  const srtParser = new srtParser2()

  const changeTranscriptText = (newText: string) => {
    const parsedText = srtParser.fromSrt(newText)
    const newHasSrt = parsedText.length > 0
    props.setHasSrt(newHasSrt)
    props.setTranscriptText(newHasSrt ? srtParser.toSrt(parsedText) : newText.replace(/\s+/g,' ').trim())
  }

  const handleTranscriptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    changeTranscriptText((e.target as HTMLTextAreaElement).value)
  }

  const handleImportClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files
    if (!files) { return }

    handleFileImport(Array.from(files))
  }

  const openFileManager = () => {
    fileRef.current?.click()
  }

  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.currentTarget.classList.remove('ProjectVersionSettingsForm__upload-dragOver')

    const files = e.dataTransfer?.files
    if (!files) { return }

    handleFileImport(Array.from(files))
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    if (dragEnterCounter === 0) {
      e.currentTarget.classList.add('ProjectVersionSettingsForm__upload-dragOver')
    }
    setDragEnterCounter(dragEnterCounter + 1)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    const nextDragEnterCounter = dragEnterCounter - 1
    setDragEnterCounter(nextDragEnterCounter)
    if (nextDragEnterCounter === 0) {
      e.currentTarget.classList.remove('ProjectVersionSettingsForm__upload-dragOver')
    }
  }

  const handleFileImport = (files: File[]) => {
    if (files.length === 0) { return }

    if (files[0].size > MAX_SRT_SIZE) {
      toastr.error('Your file is too large (> 2MB).', 'Error')
      return
    }

    const fileReader = new FileReader()
    fileReader.onload = () => {
      const newTranscriptText = fileReader.result?.toString()

      if (!newTranscriptText) { return }

      const textArea = document.getElementsByClassName('ProjectVersionSettingsForm__transcript').item(0) as HTMLTextAreaElement
      textArea.value = newTranscriptText
      changeTranscriptText(newTranscriptText)
    }

    fileReader.readAsText(files[0])
  }

  const renderUploadArea = () => {
    return (
      <div
        className="ProjectVersionSettingsForm__upload"
        onClick={openFileManager}
        onDrop={onFileDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <div
          className="ProjectVersionSettingsForm__image"
        >
          <AddVideosImage />
        </div>

        <div
          className="ProjectVersionSettingsForm__text"
        >
          Click or Drag-and-Drop
        </div>
        to import your file

        <input
          ref={fileRef}
          type="file"
          className="ProjectVersionSettingsForm__file"
          onChange={handleImportClick}
        />
      </div>
    )
  }

  const renderTextAreaForTranscriptImport = () => {
    return (
      <div className="ProjectVersionSettingsForm__transcript-wrapper">
        <textarea
          onChange={handleTranscriptChange}
          placeholder="Or paste your script here..."
          className="ProjectVersionSettingsForm__transcript"
        />
        {hasSrt ? (
          <div className="ProjectVersionSettingsForm__transcript-footer">
            <div className="ProjectVersionSettingsForm__transcript-footer-icon">
              <File />
            </div>
            This SRT will be imported in the project.
          </div>
        ) : (
          <div className="ProjectVersionSettingsForm__transcript-footer">
            <div className="ProjectVersionSettingsForm__transcript-footer-icon">
              <Subtitle />
            </div>
            This plain text will be synchronized with the video.
          </div>
        )}
      </div>
    )
  }

  const renderCheckboxForSpeakerDiarization = () => {
    return (
      <div className="ProjectVersionSettingsForm__speaker-diarization">
        <Checkbox
          checked={speakerDiarizationChecked}
          setChecked={props.setSpeakerDiarizationChecked}
          label="Speaker detection"
        />
        <div className="ProjectVersionSettings__checkbox-text ProjectVersionSettingsForm__speaker-diarization-explanation">
          Useful if you have more than 1 speaker and want dubbing
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      {renderUploadArea()}
      {renderTextAreaForTranscriptImport()}
      {renderCheckboxForSpeakerDiarization()}
    </React.Fragment>
  )
}

export default ScriptImport
