import React, { useState } from 'react'
import map from 'lodash/map'
import filter from 'lodash/filter'
import classnames from 'classnames'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import WarningPopup from '@SiteComponents/WarningPopup'
import TermbaseSettingsPopup from '@SiteComponents/TermbaseSettingsPopup'
import TermbasesTable from './TermbasesTable'
import DeleteTermbase from '@Images/delete_customization_item.svg'
import EditTermbase from '@Images/edit_customization_item.svg'
import TermAdd from '@Images/term_add.svg'
import Search from '@Images/search_termbase.svg'

import {
  ITermbase,
  ITermbaseEntry,
  ISetActiveTermbaseIdPayload,
  ISetActivePagePayload,
  ISetSearchTermPayload,
  ICreateTermbasePayload,
  IUpdateTermbasePayload,
  IUpdateTermbaseEntryPayload,
  IAddTermbaseEntryPayload,
  ISaveTermbasePayload,
} from '@SiteModules/Termbase/types'

import './TermbasesFormBody.scss'

interface TermbasesFormBodyProps {
  readonly termbases: ITermbase[]
  readonly termbaseEntries: ITermbaseEntry[]
  readonly activeTermbase?: ITermbase
  readonly editedTerm: string | null
  readonly editedPronunciation: string | null
  readonly editedTermbaseEntryId: number | null
  readonly editedTermbaseEntryDataItemIndex: number | null
  readonly isBusy: boolean
  readonly activePage: number
  readonly totalTermbaseEntryCount: number
  readonly totalPages: number
  readonly searchTerm?: string
  readonly allFullnameLanguages: string[]
  setDelayedActionType(delayedActionType: string): void
  setDelayedActionItem(delayedActionItem: number | null): void
  setActiveTermbaseId(payload: ISetActiveTermbaseIdPayload): void
  setActivePage(payload: ISetActivePagePayload): void
  setEditedTerm(editedTerm: string | null): void
  setEditedPronunciation(editedPronunciation: string | null): void
  setEditedTermbaseEntryId(editedTermBaseEntryId: number | null): void
  setEditedTermbaseEntryDataItemIndex(editedTermBaseEntryDateItemIndex: number | null): void
  createTermbase(payload: ICreateTermbasePayload): void
  updateTermbase(payload: IUpdateTermbasePayload): void
  delayedAction(): void
  updateTermbaseEntry(payload: IUpdateTermbaseEntryPayload): void
  addTermbaseEntry(payload: IAddTermbaseEntryPayload): void
  setSearchTerm(payload: ISetSearchTermPayload): void
  setKeepAfterLoading(keepAfterLoading: string | null): void
}

const HEIGHT_OF_ITEM = 40 // px Must be the same as height of .TermbasesFormBody__termbase
const MARGIN_BOTTOM_OF_ITEM = 16 // px Must be the same as margin-bottom of .TermbasesFormBody__termbase
const ITEMS_PER_PAGE = 6 // As set in paginates_per in app/models/termbase_entry.tsx
const WIDTH_OF_PAGE_COUNTER = 32 // px Must be the same as width of .TermbasesFormBody_pagination-page
const MARGIN_RIGHT_OF_PAGE_COUNTER = 8 // px Must be the same as margin-right of .TermbasesFormBody_pagination-page
const MARGIN_RIGHT_OF_PAGE_COUNTERS = 8 // px Must be the same as margin-right of .TermbasesFormBody_pagination-pages

const TermbasesFormBody: React.FC<TermbasesFormBodyProps> = props => {
  const {
    termbases,
    termbaseEntries,
    activeTermbase,
    editedTerm,
    editedPronunciation,
    editedTermbaseEntryId,
    editedTermbaseEntryDataItemIndex,
    isBusy,
    activePage,
    totalTermbaseEntryCount,
    totalPages,
    searchTerm,
    allFullnameLanguages,
  } = props

  const [deleteTermbaseConfirmationPopupOpen, setDeleteTermbaseConfirmationPopupOpen] = useState(false)
  const [deleteTermbaseEntryConfirmationPopupOpen, setDeleteTermbaseEntryConfirmationPopupOpen] = useState(false)
  const [termbaseSettingsPopupOpen, setTermbaseSettingsPopupOpen] = useState(false)
  const [termbaseToSetUp, setTermbaseToSetUp] = useState<ITermbase | undefined>()

  const hasPages = totalPages > 0
  const pageCounter = Array.from({ length: totalPages }, (_, i) => i + 1)

  const otherTermbases = termbaseToSetUp ? filter(termbases, termbase => termbase.id !== termbaseToSetUp.id) : termbases
  const otherTermbasesNames = map(otherTermbases, otherTermbase => otherTermbase.attributes.name)

  const openDeleteTermbaseConfirmationPopup = () => {
    setDeleteTermbaseConfirmationPopupOpen(true)
  }

  const openDeleteTermbaseEntryConfirmationPopup = () => {
    setDeleteTermbaseEntryConfirmationPopupOpen(true)
  }

  const closeDeleteConfirmationPopup = () => {
    setDeleteTermbaseConfirmationPopupOpen(false)
    setDeleteTermbaseEntryConfirmationPopupOpen(false)

    props.setDelayedActionType('')
    props.setDelayedActionItem(null)
  }

  const openTermbaseSettingsPopupForCreate = () => {
    disableScrollingOfPage(true)
    setTermbaseSettingsPopupOpen(true)
  }

  const openTermbaseSettingsPopupForUpdate = () => {
    disableScrollingOfPage(true)
    setTermbaseToSetUp(activeTermbase)
    setTermbaseSettingsPopupOpen(true)
  }

  const closeTermbaseSettingsPopup = () => {
    setTermbaseSettingsPopupOpen(false)
    setTermbaseToSetUp(undefined)
    disableScrollingOfPage(false)
  }

  const disableScrollingOfPage = (disable: boolean) => {
    const container = document.getElementsByClassName('Termbases')
    if (container && container.length > 0) {
      const containerElement = container[0] as HTMLElement
      containerElement.style.height = disable ? '100%' : 'auto'
      containerElement.style.overflowY = disable ? 'hidden' : 'scroll'
    }
  }

  const changeTermbase = (termbase: ITermbase) => {
    props.setActiveTermbaseId({ activeTermbaseId: termbase.id })
  }

  const saveTermbase = (payload: ISaveTermbasePayload) => {
    if (termbaseToSetUp) {
      props.updateTermbase({ id: termbaseToSetUp.id, ...payload })
    } else {
      props.createTermbase(payload)
    }
  }

  const deleteTermbase = () => {
    props.setDelayedActionType('deleteTermbase')

    openDeleteTermbaseConfirmationPopup()
  }

  const deleteTermbaseEntry = (termbaseEntryId: number) => {
    props.setDelayedActionType('deleteTermbaseEntry')
    props.setDelayedActionItem(termbaseEntryId)

    openDeleteTermbaseEntryConfirmationPopup()
  }

  const addTermbaseEntry = () => {
    props.addTermbaseEntry({ id: activeTermbase!.id })
  }

  const renderNameAndActions = (termbase: ITermbase) => {
    const isActive = activeTermbase && activeTermbase.id === termbase.id

    return (
      <React.Fragment>
        <span
          className="TermbasesFormBody__termbase-name"
        >
          {termbase.attributes.name}
        </span>
        {isActive && (
          <DeleteTermbase
            className="TermbasesFormBody__termbase-delete"
            onClick={deleteTermbase}
          />
        )}
        {isActive && (
          <EditTermbase
            className="TermbasesFormBody__termbase-edit"
            onClick={openTermbaseSettingsPopupForUpdate}
          />
        )}
      </React.Fragment>
    )
  }

  const termbasesToRender = map(termbases, (termbase, index) => {
    const isActive = activeTermbase === termbase

    const handleChangeTermbase = () => {
      if (isBusy) { return }

      if (!activeTermbase || termbase.id !== activeTermbase.id) {
        changeTermbase(termbase)
      }
    }

    return (
      <li
        key={index}
        className={classnames(
          'TermbasesFormBody__termbase', {
            'TermbasesFormBody__termbase-active': isActive
          }
        )}
        onClick={handleChangeTermbase}
      >
        {renderNameAndActions(termbase)}
      </li>
    )
  })

  const handleChangeSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setSearchTerm({ searchTerm: (e.target as HTMLInputElement).value })
  }

  const renderTermbases = () => {
    const activeTermbaseIndex = activeTermbase ? termbases.indexOf(activeTermbase) : 0
    const topValueOfActiveLine = activeTermbaseIndex * (HEIGHT_OF_ITEM + MARGIN_BOTTOM_OF_ITEM)

    return (
      <div className="TermbasesFormBody__termbases-wrapper">
        <ul className="TermbasesFormBody__termbases">
          {termbasesToRender}
        </ul>
        {activeTermbase && (
          <div
            className="TermbasesFormBody__termbases-active-line"
            style={{top: `${topValueOfActiveLine}px`}}
          />
        )}
      </div>
    )
  }

  const renderLeftPart = () => {
    return (
      <div className="TermbasesFormBody__left">
        {renderTermbases()}
        <ButtonNewV2
          styleType="brand-tertiary"
          caption="New Term Base"
          size="small"
          onClick={openTermbaseSettingsPopupForCreate}
        />
      </div>
    )
  }

  const renderSearchAndAddNewTerm = () => {
    const shouldShowSearchIcon = !searchTerm

    return (
      <div className="TermbasesFormBody__search-and-new-term-wrapper">
        <div className="TermbasesFormBody__search">
          {shouldShowSearchIcon && (
            <Search />
          )}
          <input
            placeholder="Search..."
            autoComplete="off"
            value={searchTerm || ''}
            onChange={handleChangeSearchTerm}
          />
        </div>
        <ButtonNewV2
          styleType="brand-primary"
          caption="Add New Term"
          icon={<TermAdd/>}
          size="large"
          onClick={addTermbaseEntry}
        />
      </div>
    )
  }

  const renderTermbasesTable = () => {
    return (
      <TermbasesTable
        termbaseEntries={termbaseEntries}
        editedTerm={editedTerm}
        editedPronunciation={editedPronunciation}
        editedTermbaseEntryId={editedTermbaseEntryId}
        editedTermbaseEntryDataItemIndex={editedTermbaseEntryDataItemIndex}
        setEditedTerm={props.setEditedTerm}
        setEditedPronunciation={props.setEditedPronunciation}
        setEditedTermbaseEntryId={props.setEditedTermbaseEntryId}
        setEditedTermbaseEntryDataItemIndex={props.setEditedTermbaseEntryDataItemIndex}
        updateTermbaseEntry={props.updateTermbaseEntry}
        deleteTermbaseEntry={deleteTermbaseEntry}
        setKeepAfterLoading={props.setKeepAfterLoading}
      />
    )
  }

  const pages = map(pageCounter, (pageCount, index) => {
    const isActive = activePage === pageCount

    const handleSetActivePage = () => {
      props.setActivePage({ activePage: pageCount })
    }

    return (
      <li
        key={index}
        className={classnames(
          'TermbasesFormBody_pagination-page', {
            'TermbasesFormBody_pagination-page-active': isActive
          }
        )}
        onClick={handleSetActivePage}
      >
        <span>
          {pageCount}
        </span>
      </li>
    )
  })

  const renderPagination = () => {
    const min = (activePage - 1) * ITEMS_PER_PAGE + 1
    const max = Math.min(activePage * ITEMS_PER_PAGE, totalTermbaseEntryCount)

    const rightValueOfActiveBackground = (totalPages - activePage) * (WIDTH_OF_PAGE_COUNTER + MARGIN_RIGHT_OF_PAGE_COUNTER) + MARGIN_RIGHT_OF_PAGE_COUNTERS + MARGIN_RIGHT_OF_PAGE_COUNTER
    const showingText = totalTermbaseEntryCount === 0 ? '0' : `${min}-${max}`

    return (
      <div className="TermbasesFormBody_pagination-wrapper">
        <div className="TermbasesFormBody_pagination-left">
          {`Showing ${showingText} of ${totalTermbaseEntryCount} items`}
        </div>
        <div className="TermbasesFormBody_pagination-right">
          {hasPages && (
            <div
              className="TermbasesFormBody_pagination-page-active-background"
              style={{right: `${rightValueOfActiveBackground}px`}}
            />
          )}
          <ul className="TermbasesFormBody_pagination-pages">
            {pages}
          </ul>
        </div>
      </div>
    )
  }

  const renderRightPart = () => {
    return (
      <div className="TermbasesFormBody__right">
        {renderSearchAndAddNewTerm()}
        {renderTermbasesTable()}
        {renderPagination()}
      </div>
    )
  }

  const renderDeleteConfirmationPopup = (entity: string) => {
    return (
      <WarningPopup
        closePopup={closeDeleteConfirmationPopup}
        delayedAction={props.delayedAction}
        header={`Deleting the ${entity}`}
        description={`Confirm that you want to delete the ${entity}. This action cannot be undone.`}
        captionButton1="Abort"
        captionButton2="Delete"
        useEnterToConfirm
      />
    )
  }

  const renderTermbaseSettingsPopup = () => {
    return (
      <TermbaseSettingsPopup
        allFullnameLanguages={allFullnameLanguages}
        termbaseToSetUp={termbaseToSetUp}
        closePopup={closeTermbaseSettingsPopup}
        saveTermbase={saveTermbase}
        otherTermbasesNames={otherTermbasesNames}
      />
    )
  }

  return (
    <div className="TermbasesFormBody">
      {renderLeftPart()}
      {activeTermbase && renderRightPart()}
      {deleteTermbaseConfirmationPopupOpen && renderDeleteConfirmationPopup('term base')}
      {deleteTermbaseEntryConfirmationPopupOpen && renderDeleteConfirmationPopup('term')}
      {termbaseSettingsPopupOpen && renderTermbaseSettingsPopup()}
    </div>
  )
}

export default TermbasesFormBody
