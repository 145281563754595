import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '../../rootReducer'
import Header from '@SiteContainers/Header'
import YoutubeLogo from '@Images/youtube_logo.png'
import VimeoIcon from '@Images/vimeo_icon.svg'

import './ChannelsNew.scss'

import {
  connectExternalChannel
} from '@SiteModules/User/actions'

import { IConnectExternalChannel } from '@Root/site/modules/User/types'

interface ChannelsNewProps {
  connectExternalChannel(payload: IConnectExternalChannel): void
}

interface ChannelsNewState {}

class ChannelsNew extends React.Component<ChannelsNewProps, ChannelsNewState> {
  handleVimeoConnectClick = () => {
    this.props.connectExternalChannel({ service: 'vimeo' })
  }

  handleYoutubeConnectClick = () => {
    this.props.connectExternalChannel({ service: 'youtube' })
  }

  renderYoutubeButton = () => {
    return (
      <div className="ChannelsNew__channel-button">
        <img src={YoutubeLogo} className="ChannelsNew__channel-button-logo"/>
        <div className="ChannelsNew__channel-button-connect"
          onClick={this.handleYoutubeConnectClick}
        >
          Connect
        </div>
      </div>
    )
  }

  renderVimeoButton = () => {
    return (
      <div className="ChannelsNew__channel-button">
        <VimeoIcon />
        <div className="ChannelsNew__channel-button-name">
          Vimeo
        </div>
        <div
          className="ChannelsNew__channel-button-connect"
          onClick={this.handleVimeoConnectClick}
        >
          Connect
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="ChannelsNew">
        <Header />
        <div className="ChannelsNew__title">
          Connect a new channel
        </div>
        <div className="ChannelsNew__description">
          Publish and create your subtitling and translation projects in a few clicks.
        </div>
        <div className="ChannelsNew__channel-button-wrapper">
          {this.renderYoutubeButton()}
          {this.renderVimeoButton()}
        </div>
      </div>
    )
  }
}

function mapStateToProps(_state: IApplicationState) {
  return {}
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    connectExternalChannel
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsNew)
