import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import DemoHeader from '@SiteComponents/DemoHeader'
import DemoFooter from '@SiteContainers/DemoFooter'
import DemoProjectVersionSettingsForm from '@SiteComponents/DemoProjectVersionSettingsForm'
import { Navigate } from 'react-router-dom'

import {
  submitDemoProjectVersionSettings,
  setSubmitted,
} from '@SiteModules/Demo/actions'

import {
  IProjectVersionSettings,
} from '@SitePages/ProjectVersionSettings/types'

import {
  ISubmitDemoProjectVersionSettingsPayload,
} from '@SiteModules/Demo/types'

import '@SitePages/ProjectVersionSettings/ProjectVersionSettings.scss'
import './DemoProjectVersionSettings.scss'

interface DemoProjectVersionSettingsProps {
  readonly loading: boolean
  readonly projectVersionSettings: IProjectVersionSettings
  readonly videoUrl: string
  readonly needsDownload: boolean
  readonly submitted: boolean
  submitDemoProjectVersionSettings(payload: ISubmitDemoProjectVersionSettingsPayload): void
  setSubmitted(): void
}

interface DemoProjectVersionSettingsState {
}

class DemoProjectVersionSettings extends React.Component<DemoProjectVersionSettingsProps, DemoProjectVersionSettingsState> {
  render() {
    const {
      loading,
      projectVersionSettings,
      videoUrl,
      submitted,
      needsDownload,
    } = this.props

    const {
      languagesForVideos,
      translationLanguages,
    } = projectVersionSettings.attributes

    const hasUrl = videoUrl.length > 0

    return (
      <React.Fragment>
        {hasUrl ? (
          <div className="ProjectVersionSettings DemoProjectVersionSettings">
            <DemoHeader />
            <DemoProjectVersionSettingsForm
              videoUrl={videoUrl}
              hasLoaded={!loading}
              showSpinner={loading}
              disableButton={loading}
              submitDemoProjectVersionSettings={this.props.submitDemoProjectVersionSettings}
              languagesForVideos={languagesForVideos}
              translationLanguages={translationLanguages}
              submitted={submitted}
              setSubmitted={this.props.setSubmitted}
              needsDownload={needsDownload}
            />
            <DemoFooter />
          </div>
        ) : (
          <Navigate to="/demo/upload"/>
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { projectVersionSettings, demo } = state

  return {
    videoUrl: demo.videoUrl,
    loading: projectVersionSettings.loading,
    projectVersionSettings: projectVersionSettings.projectVersionSettings,
    submitted: demo.submitted,
    needsDownload: demo.needsDownload,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    submitDemoProjectVersionSettings,
    setSubmitted,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoProjectVersionSettings)
