import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Popup from '@SiteComponents/Popup'
import Button from '@SiteComponents/Button'
import Checkbox from '@EditorComponents/Checkbox'

import {
  IImportSrtPayload,
} from './types'

import {
  importSrt,
} from './actions'

import AttentionIcon from '@Images/attention.svg'

import './ImportSrtPopup.scss'

interface ImportSrtProps {
  readonly projectVersionId: string
  readonly loading: boolean
  readonly subtitleId: number
  closePopup(): void
  importSrt(payload: IImportSrtPayload): void
}

interface ImportSrtState {
  enableSpeakerDiarization: boolean
}

class ImportSrt extends React.Component<ImportSrtProps, ImportSrtState> {
  fileRef: React.RefObject<HTMLInputElement>

  constructor(props: ImportSrtProps) {
    super(props)

    this.fileRef = React.createRef()

    this.state = {
      enableSpeakerDiarization: false
    }
  }

  openFileManager = () => {
    if (!this.fileRef) { return }
    if (!this.fileRef.current) { return }

    this.fileRef.current.click()
  }

  uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files

    if (!files) { return }

    const fileSrt = files[0]

    const {
      projectVersionId,
      subtitleId,
    } = this.props

    const {
      enableSpeakerDiarization,
    } = this.state

    const payload = { fileSrt, projectVersionId, subtitleId, enableSpeakerDiarization }
    this.props.importSrt(payload)

    if (this.fileRef && this.fileRef.current) {
      this.fileRef.current.value = ''
    }
  }

  setEnableSpeakerDiarization = (enableSpeakerDiarization: boolean) => {
    this.setState({ enableSpeakerDiarization })
  }

  render() {
    const { closePopup, loading } = this.props

    return (
      <Popup
        className="ImportSrtPopup"
        closePopup={closePopup}
        isDark
      >
        <div className="ImportSrtPopup__form">
          <div className="ImportSrtPopup__title">Subtitle import</div>
          <p className="ImportSrtPopup__description">
            <AttentionIcon className="ImportSrtPopup__description-icon" />
            Be careful, the import will erase the subtitles already created.
          </p>
          <Checkbox
            checked={this.state.enableSpeakerDiarization}
            onChange={this.setEnableSpeakerDiarization}
            label={(
              <span className="ImportSrtPopup__checkbox-label">
                Speaker detection <br/>
                <span className="ImportSrtPopup__checkbox-label-explanation">
                  Useful if you have more than 1 speaker and want dubbing
                </span>
              </span>
            )}
          />

          <Button
            caption="Import a new srt file"
            color="primary-dark"
            onClick={this.openFileManager}
            showSpinner={loading}
            disabled={loading}
          />

          <input
            type="file"
            style={{ display: 'none' }}
            ref={this.fileRef}
            accept=".srt"
            onChange={this.uploadFile}
          />
        </div>
      </Popup>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { projectVersionsList } = state

  return {
    loading: state.importSrt.loading,
    projectVersionId: projectVersionsList.editingProjectVersionIds[0],
    subtitleId: projectVersionsList.subtitleId,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    importSrt
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportSrt)
