import React from 'react'
import Loader from '@EditorComponents/Loader'

import './PageLoader.scss'

interface PageLoaderProps {}
const PageLoader: React.FC<PageLoaderProps> = _props => {
  return (
    <React.Fragment>
      <div className="Overlay" />
      <div className="Loader">
        <Loader />
      </div>
    </React.Fragment>
  )
}

export default PageLoader
