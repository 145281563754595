import {
  IUserInfo,
  IUserStripeInfo,
  UserActionTypes,
  IConnectExternalChannel,
  IOpenSsoAdminPortal,
  ISetLastViewedAlert,
} from './types'

import {
  IGetExportingSubtitleIdsSuccessPayload,
  IGetExportingProjectVersionIdsSuccessPayload,
} from '@EditorModules/User/types'

export const logout = (payload: string) => ({
  type: UserActionTypes.LOGOUT,
  payload
})

export const logoutFailure = () => ({
  type: UserActionTypes.LOGOUT_FAILURE,
})

export const logoutSuccess = () => ({
  type: UserActionTypes.LOGOUT_SUCCESS,
})

export const getUserInfo = () => ({
  type: UserActionTypes.GET_INFO,
})

export const getUserInfoSuccess = (payload: IUserInfo) => ({
  type: UserActionTypes.GET_INFO_SUCCESS,
  payload,
})

export const getUserInfoFailure = () => ({
  type: UserActionTypes.GET_INFO_FAILURE,
})

export const getUserStripeInfo = () => ({
  type: UserActionTypes.GET_STRIPE_INFO,
})

export const getUserStripeInfoSuccess = (payload: IUserStripeInfo) => ({
  type: UserActionTypes.GET_STRIPE_INFO_SUCCESS,
  payload,
})

export const getUserStripeInfoFailure = () => ({
  type: UserActionTypes.GET_STRIPE_INFO_FAILURE,
})

export const connectExternalChannel = (payload: IConnectExternalChannel) => ({
  type: UserActionTypes.CONNECT_EXTERNAL_CHANNEL,
  payload
})

export const createCustomerPortalSession = () => ({
  type: UserActionTypes.CREATE_CUSTOMER_PORTAL_SESSION
})

export const createCustomerPortalSessionFailure = () => ({
  type: UserActionTypes.CREATE_CUSTOMER_PORTAL_SESSION_FAILURE,
})

export const openSsoAdminPortal = (payload: IOpenSsoAdminPortal) => ({
  type: UserActionTypes.OPEN_SSO_ADMIN_PORTAL,
  payload
})

export const setLastViewedAlert = (payload: ISetLastViewedAlert) => ({
  type: UserActionTypes.SET_LAST_VIEWED_ALERT,
  payload
})

export const getExportingSubtitleIds = () => {
  return {
    type: UserActionTypes.GET_EXPORTING_SUBTITLE_IDS,
  }
}

export const getExportingSubtitleIdsSuccess = (payload: IGetExportingSubtitleIdsSuccessPayload) => {
  return {
    type: UserActionTypes.GET_EXPORTING_SUBTITLE_IDS_SUCCESS,
    payload,
  }
}

export const getExportingProjectVersionIds = () => {
  return {
    type: UserActionTypes.GET_EXPORTING_PROJECT_VERSION_IDS,
  }
}

export const getExportingProjectVersionIdsSuccess = (payload: IGetExportingProjectVersionIdsSuccessPayload) => {
  return {
    type: UserActionTypes.GET_EXPORTING_PROJECT_VERSION_IDS_SUCCESS,
    payload,
  }
}
