import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import some from 'lodash/some'
import map from 'lodash/map'
import S3 from 'aws-sdk/clients/s3'
import router from '@Site/Router'
import { IApplicationState } from '@Site/rootReducer'
import DemoHeader from '@SiteComponents/DemoHeader'
import DemoFooter from '@SiteContainers/DemoFooter'
import UploadArea from '@SitePages/Upload/UploadArea'
import SubmitUrlsArea from '@SitePages/Upload/SubmitUrlsArea'
import UploadingProgress from '@SitePages/Upload/UploadingProgress'
import Loader from '@SiteComponents/Loader'
import ElementUploader, { IElementUploaderOptions, IUploadedElement } from '@Utils/ElementUploader'
// import NabshowLogo from '@Images/nabshow_logo.svg'
// import AfcLogo from '@Images/afc_logo.png'
// import IbcLogo from '@Images/ibc_logo.png'
import ChecksubLogo from '@Images/demo_logo_checksub.png'

import {
  initializeUpload,
  resetUpload,
} from '@SiteModules/Element/actions'

import {
  saveUrlInState
} from '@SiteModules/Demo/actions'

import {
  fetchProjectVersionSettingsDemo,
} from '@SitePages/ProjectVersionSettings/actions'

import {
  IInitializeUploadPayload,
  ISubmitUrlsPayload,
} from '@SiteModules/Element/types'

import {
  ISaveUrlInStatePayload,
} from '@SiteModules/Demo/types'

import '@SitePages/Upload/Upload.scss'
import './DemoUpload.scss'

const DEFAULT_STRIKE_COLOR = '#43C78F' // bg_default
const NEGATIVE_WORKSPACE_ID = -1

interface DemoUploadProps {
  readonly uploading: boolean
  readonly numberOfFilesToUpload: number
  readonly alreadyUploaded: number
  readonly workspaceId: number
  initializeUpload(payload: IInitializeUploadPayload): void
  resetUpload(): void
  saveUrlInState(payload: ISaveUrlInStatePayload): void
  fetchProjectVersionSettingsDemo(): void
}

interface DemoUploadState {
  readonly filesToUpload: File[]
  readonly progress: number
  readonly strikeColor: string
  readonly uploadingFileName: string
  readonly showUploadingProgress: boolean
  readonly cancelUploadingCounter: number
  readonly warningMessages: string[]
}

let uploadingRequest: S3.ManagedUpload

class DemoUpload extends React.Component<DemoUploadProps, DemoUploadState> {
  DEFAULT_VIDEO_URL = 'https://checksub-video-asset.s3.eu-west-1.amazonaws.com/user/2431/_This_is_the_most_important_event_in_the_world_.mp4' // To be updated later
  MAX_VIDEO_SIZE = 1073741824 // 1Gb
  MAX_NUMBER_OF_URLS_TO_SUBMIT = 1
  BYTES_PER_GIGABYTE = 1073741824
  dragEnterCounter = 0

  state: DemoUploadState = {
    filesToUpload: [],
    progress: 0,
    strikeColor: DEFAULT_STRIKE_COLOR,
    uploadingFileName: '',
    showUploadingProgress: false,
    cancelUploadingCounter: 0,
    warningMessages: [],
  }

  fileRef: React.RefObject<HTMLInputElement>

  constructor(props: DemoUploadProps) {
    super(props)

    this.fileRef = React.createRef()
  }

  componentDidMount() {
    this.props.fetchProjectVersionSettingsDemo()
  }

  componentDidUpdate(prevProps: DemoUploadProps) {
    const { filesToUpload, showUploadingProgress } = this.state
    const { uploading, numberOfFilesToUpload, alreadyUploaded } = this.props
    const shouldUploadNextFile = prevProps.uploading !== uploading && uploading === false && alreadyUploaded < numberOfFilesToUpload
    const shouldStopShowUploadingProgress = alreadyUploaded === numberOfFilesToUpload && alreadyUploaded !== 0

    if (showUploadingProgress) {
      if (shouldUploadNextFile) {
        this.uploadFile(filesToUpload[alreadyUploaded])
      }

      if (shouldStopShowUploadingProgress) {
        this.setState({ showUploadingProgress: false })
        window.location.href = '/'
      }
    }
  }

  setWarningMessages = (newWarningMessages: string[]) => {
    this.setState({ warningMessages: newWarningMessages })
  }

  openFileManager = () => {
    if (!this.fileRef) { return }
    if (!this.fileRef.current) { return }

    this.fileRef.current.click()
  }

  onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()

    const filesList = e.dataTransfer?.files
    if (filesList) {
      const filesToUpload = Array.from(filesList).filter((item) => {
        return item.type.startsWith('video/') || item.type.startsWith('audio/')
      })

      if (filesToUpload.length > 0) {
        this.handleUpload([filesToUpload[0]])
      }
    }
  }

  handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    if (this.dragEnterCounter === 0) {
      e.currentTarget.classList.add('Upload__upload-area-dragOver')
    }
    this.dragEnterCounter++
  }

  handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    this.dragEnterCounter--
    if (this.dragEnterCounter === 0) {
      e.currentTarget.classList.remove('Upload__upload-area-dragOver')
    }
  }

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  handleUpload = (filesToUpload: File[]) => {
    this.setWarningMessages([])

    if (some(filesToUpload, (file: File) => file.size > this.MAX_VIDEO_SIZE)){
      this.setWarningMessages([`Video cannot be uploaded because of its size (> ${(this.MAX_VIDEO_SIZE / this.BYTES_PER_GIGABYTE).toFixed(0)} GB)`])
      return
    }

    this.props.initializeUpload({ numberOfFilesToUpload: filesToUpload.length })

    this.setState({ filesToUpload }, () => {
      this.uploadFile(filesToUpload[0])
    })
  }

  handleUploadClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files
    if (!files) { return }

    this.handleUpload(Array.from(files))
  }

  uploadFile = (file: File) => {
    const options: IElementUploaderOptions = {
      uploadElementCallback: (params: IUploadedElement) => {
        this.setState({ progress: 100 })
        this.continueWithSetup(params.url, false)
      },
      showProgressCallback: (data: S3.ManagedUpload.Progress) => {
        const progress = data.loaded / file.size * 100
        this.setState({ progress })
      },
      workspaceId: NEGATIVE_WORKSPACE_ID,
    }

    uploadingRequest = new ElementUploader(file, options).call()
    this.setState({
      progress: 0,
      strikeColor: DEFAULT_STRIKE_COLOR,
      uploadingFileName: file.name,
      showUploadingProgress: true
    })
  }

  cancelUploading = () => {
    uploadingRequest.abort()
    this.props.resetUpload()
    this.setState({
      progress: 0,
      strikeColor: DEFAULT_STRIKE_COLOR,
      uploadingFileName: '',
      showUploadingProgress: false,
      filesToUpload: [],
      cancelUploadingCounter: this.state.cancelUploadingCounter + 1
    })
  }

  continueWithSetup = (url: string, needsDownload: boolean) => {
    this.props.saveUrlInState({ videoUrl: url, needsDownload })
    router.navigate('/demo/setup')
  }

  submitUrls = (payload: ISubmitUrlsPayload) => {
    this.continueWithSetup(payload.urls[0], true)
  }

  continueWithRandomVideo = () => {
    this.continueWithSetup(this.DEFAULT_VIDEO_URL, false)
  }

  renderUploadingProgress = () => {
    const { progress, strikeColor, uploadingFileName } = this.state
    const { alreadyUploaded, numberOfFilesToUpload } = this.props

    return(
      <div className="Upload__uploading-progress">
        {/* <NabshowLogo className="DemoUpload__nabshow-logo"/> */}
        {/* <img src={AfcLogo} className="DemoUpload__afc-logo"/> */}
        {/* <img src={IbcLogo} className="DemoUpload__ibc-logo"/> */}
        <img src={ChecksubLogo} className="DemoUpload__cs-logo"/>
        <div className="Upload__header">We're uploading your video</div>
        <UploadingProgress
          progress={progress}
          strikeColor={strikeColor}
          cancelUploading={this.cancelUploading}
          uploadingFileName={uploadingFileName}
          alreadyUploaded={alreadyUploaded}
          numberOfFilesToUpload={numberOfFilesToUpload}
        />
      </div>
    )
  }

  renderUploadForm = () => {
    return (
      <div className="Upload__form">
        <div className="Upload__form-top">
          {/* <NabshowLogo className="DemoUpload__nabshow-logo"/> */}
          {/* <img src={AfcLogo} className="DemoUpload__afc-logo"/> */}
          {/* <img src={IbcLogo} className="DemoUpload__ibc-logo"/> */}
          <img src={ChecksubLogo} className="DemoUpload__cs-logo"/>
          <div className="Upload__header">Translate a video now with Checksub</div>
          {this.renderUploadArea()}
          {this.renderSubmitUrlsArea()}
          {this.renderWarnings()}
          {/* {this.renderNoVideos()} */}
        </div>
      </div>
    )
  }

  renderUploadArea = () => {
    return (
      <UploadArea
        key={this.state.cancelUploadingCounter} // Necessary to trigger onChange for the input if canceling a first upload and uploading the same files again
        fileRef={this.fileRef}
        openFileManager={this.openFileManager}
        onFileDrop={this.onFileDrop}
        handleDragEnter={this.handleDragEnter}
        handleDragLeave={this.handleDragLeave}
        handleUpload={this.handleUpload}
        handleUploadClick={this.handleUploadClick}
        handleDragOver={this.handleDragOver}
        shouldRenderFreeTrialMessage={false}
        limitToOneFile
      />
    )
  }

  renderSubmitUrlsPlaceHolder = () => {
    return (
      <React.Fragment>
        <span className="DemoUpload__submitUrls-placeholder-long">Or paste your URL here... <br />We support video links from YouTube, Vimeo and more.</span>
        <span className="DemoUpload__submitUrls-placeholder-short">Or paste a URL here (YouTube or Vimeo)</span>
      </React.Fragment>
    )
  }

  renderSubmitUrlsArea = () => {
    return (
      <SubmitUrlsArea
        uploading={this.props.uploading}
        submitUrls={this.submitUrls}
        setWarningMessages={this.setWarningMessages}
        maxNumberOfUrlsToSubmit={this.MAX_NUMBER_OF_URLS_TO_SUBMIT}
        placeholder={this.renderSubmitUrlsPlaceHolder()}
        buttonCaption="Try for free"
      />
    )
  }

  renderWarnings = () => {
    const shouldShowWarningList = this.state.warningMessages.length > 0

    return (
      <div className="Upload__warnings">
        {shouldShowWarningList && (
          <ul className="Upload__warnings-list">
            {map(this.state.warningMessages, (warningMessage, index) => {
              return (
                <li key={index}>
                  {warningMessage}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    )
  }

  renderNoVideos = () => {
    return (
      <div
        className="DemoUpload__no-video"
        onClick={this.continueWithRandomVideo}
      >
        No video? Use one of our random videos.
      </div>
    )
  }

  renderLoader = () => {
    return (
      <Loader color="dark" />
    )
  }

  render() {
    const shouldRenderLoader = this.props.uploading && !this.state.showUploadingProgress
    const shouldRenderUploadingProgress = this.state.showUploadingProgress
    const shouldRenderUploadForm = !shouldRenderLoader && !shouldRenderUploadingProgress

    return (
      <div className="Upload DemoUpload">
        <DemoHeader />
        {shouldRenderLoader && this.renderLoader()}
        {shouldRenderUploadForm && this.renderUploadForm()}
        {shouldRenderUploadingProgress && this.renderUploadingProgress()}
        <DemoFooter />
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { elements, user } = state

  return {
    uploading: elements.uploading,
    numberOfFilesToUpload: elements.numberOfFilesToUpload,
    alreadyUploaded: elements.alreadyUploaded,
    workspaceId: user.user.attributes.workspaceId,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    initializeUpload,
    resetUpload,
    saveUrlInState,
    fetchProjectVersionSettingsDemo,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoUpload)
