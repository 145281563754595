import React from 'react'

interface EmailInputProps {
  readonly email: string
  setEmail(newEmail: string): void
}

const EmailInput: React.FC<EmailInputProps> = props => {
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setEmail((e.target as HTMLInputElement).value)
  }

  return (
    <div
      className="ProjectVersionSettingsForm__formfield EmailInput__formfield"
    >
      <label className="ProjectVersionSettingsForm__label EmailInput__label-long" htmlFor="email">
        Please enter your email address to receive the processed video:
      </label>
      <label className="ProjectVersionSettingsForm__label EmailInput__label-short" htmlFor="email">
        Email to receive the video:
      </label>
      <input
        className="DemoProjectVersionSettingsForm__email"
        value={props.email}
        onChange={onChangeEmail}
        placeholder="Your email"
      />
    </div>
  )
}

export default EmailInput
