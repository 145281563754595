import React from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import { Submittable } from '@Root/types'
import DropdownList from 'react-widgets/DropdownList'
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form'

import '@Root/config/dropdowns.scss'
import './SurveyForm.scss'

interface SurveyFormProps extends Submittable {
}

type FormData = {
  role: string
  videoQuantityPerMonth: string
  organizationSize: string
}

const ROLES = [
  'Learning Manager',
  'Social Media Manager',
  'Content Manager',
  'Marketing Project Manager',
  'Video Editor',
  'Content Creator',
  'Influencer',
  'CEO/Founder',
  'Student',
  'Subtitler',
  'Other'
]

const VIDEO_QUANTITY_PER_MONTH = [
  '1 (or less)',
  '2 to 10',
  '11 or more'
]

const ORGANIZATION_SIZES = [
  'one (myself)',
  '1-10',
  '11-50',
  '51-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5001-10,000'
]

const SELF_EMPLOYED = 'Self-employed'

const SurveyForm: React.FC<SurveyFormProps> = props => {
  const { control, handleSubmit, formState: { errors } } = useForm<FormData>()

  const onSubmit = handleSubmit(({ role, videoQuantityPerMonth, organizationSize }) => {
    if (organizationSize === ORGANIZATION_SIZES[0]) {
      organizationSize = SELF_EMPLOYED
    }
    props.submit({ role, videoQuantityPerMonth, organizationSize })
  })

  const renderRoleDropdownList = ({ field }: { field: ControllerRenderProps<FormData, 'role'> }) => {
    return (
      <DropdownList
        id="role"
        filter={false}
        selectIcon={false}
        data={ROLES}
        onChange={field.onChange}
      />
    )
  }

  const renderVideoQuantityPerMonthDropdownList = ({ field }: { field: ControllerRenderProps<FormData, 'videoQuantityPerMonth'> }) => {
    return (
      <DropdownList
        id="videoQuantityPerMonth"
        filter={false}
        selectIcon={false}
        data={VIDEO_QUANTITY_PER_MONTH}
        onChange={field.onChange}
      />
    )
  }

  const renderOrganizationSizeDropdownList = ({ field }: { field: ControllerRenderProps<FormData, 'organizationSize'> }) => {
    return (
      <DropdownList
        id="organizationSize"
        filter={false}
        selectIcon={false}
        data={ORGANIZATION_SIZES}
        onChange={field.onChange}
      />
    )
  }

  const renderRole = () => {
    return(
      <span className="SurveyForm__formfield SurveyForm__formfield-role">
        <Controller
          control={control}
          name="role"
          rules={{ required: true }}
          render={renderRoleDropdownList}
        />

        {errors.role && <span className="errorMessage">&#x26A0; This field is required</span>}
      </span>
    )
  }

  const renderVideoQuantityPerMonth = () => {
    return(
      <span className="SurveyForm__formfield SurveyForm__formfield-videoQuantityPerMonth">
        <Controller
          control={control}
          name="videoQuantityPerMonth"
          rules={{ required: true }}
          render={renderVideoQuantityPerMonthDropdownList}
        />

        {errors.videoQuantityPerMonth && <span className="errorMessage">&#x26A0; This field is required</span>}
      </span>
    )
  }

  const renderOrganizationSize = () => {
    return(
      <div className="SurveyForm__formfield SurveyForm__formfield-organizationSize">
        <Controller
          control={control}
          name="organizationSize"
          rules={{ required: true }}
          render={renderOrganizationSizeDropdownList}
        />

        {errors.organizationSize && <span className="errorMessage">&#x26A0; This field is required</span>}
      </div>
    )
  }

  const renderSubmitButton = () => {
    return(
      <ButtonNewV2
        type="submit"
        size="large"
        styleType="brand-primary"
        caption="Let's start"
        disabled={props.disableButton}
        showSpinner={props.showSpinner}
      />
    )
  }

  return(
    <div className="SurveyForm" >
      <form onSubmit={onSubmit} >
        <div className="SurveyForm__line">
          <span className="SurveyForm__text">I'm a</span>
          {renderRole()}
          <span className="SurveyForm__text">and I work in a company of</span>
          {renderOrganizationSize()}
          <span className="SurveyForm__text">person(s) 💼</span>
        </div>
        <div className="SurveyForm__line">
          <span className="SurveyForm__text">And every month I create about</span>
          {renderVideoQuantityPerMonth()}
          <span className="SurveyForm__text">videos.</span>
        </div>
        <div className="SurveyForm__line">
          {renderSubmitButton()}
        </div>
      </form>
    </div>
  )
}

export default SurveyForm
