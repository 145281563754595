import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import snakeCase from 'lodash/snakeCase'
import isPlainObject from 'lodash/isPlainObject'

interface IObject {
  [key: string]: any
}

export default function convertToSnakeCase(hash: IObject) {
  return mapKeysDeep(hash, snakeCase)
}

const mapKeysDeep = (hash: IObject, callback: (key: any) => {}): any[any] => {
  const keys = mapKeys(hash, (_value, key) => {
    return callback(key)
  })

  return mapValues(keys, (val) => {
    return isPlainObject(val) ? mapKeysDeep(val, callback) : val
  })
}
