import React, { useState } from 'react'
import Button from '@SiteComponents/Button'
import { useForm } from 'react-hook-form'

import {
  ICreateLabelPayload
} from '@SiteModules/Label/types'

import './CreateLabelForm.scss'

interface CreateLabelFormProps  {
  readonly showSpinner: boolean
  readonly disableButton: boolean
  submit(payload: ICreateLabelPayload): void
}

type FormData = {
  caption: string
}

const CreateLabelForm: React.FC<CreateLabelFormProps> = props => {
  const [newCaption, setNewCaption] = useState('')
  const { register, handleSubmit } = useForm<FormData>()

  const onSubmit = handleSubmit(({ caption }) => {
    props.submit({ caption })
  })

  const changeCaption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCaption((e.target as HTMLInputElement).value)
  }

  const shouldDisableButton = props.disableButton || !newCaption.trim()

  return(
    <form className="CreateLabelForm" onSubmit={onSubmit}>
      <input
        id="caption"
        type="text"
        value={newCaption}
        placeholder="Label caption"
        autoComplete="off"
        {...register('caption', { maxLength: 100, onChange: changeCaption })}
      />

      <Button
        type="submit"
        color="primary-dark"
        caption="Create label"
        showSpinner={props.showSpinner}
        disabled={shouldDisableButton}
      />
    </form>
  )
}

export default CreateLabelForm
