import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Popup from '@SiteComponents/Popup'
import Button from '@SiteComponents/Button'

import {
  deleteLabel,
} from './actions'

import {
  IDeleteLabelPayload,
} from './types'

import AttentionIcon from '@Images/attention.svg'

import './DeleteLabelPopup.scss'

interface DeleteLabelPopupProps {
  readonly caption: string
  readonly loading: boolean
  readonly labelId: number
  closePopup(): void
  deleteLabel(payload: IDeleteLabelPayload): void
}

interface DeleteLabelPopupState {
  readonly deleteConfirmation: string
}

class DeleteLabelPopup extends React.Component<DeleteLabelPopupProps, DeleteLabelPopupState> {
  state = {
    deleteConfirmation: ''
  }

  changeDeleteCofirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      deleteConfirmation: (e.target as HTMLInputElement).value
    })
  }

  handleConfirm = () => {
    const canDelete = this.state.deleteConfirmation === 'delete' || this.state.deleteConfirmation === 'DELETE'

    if (canDelete) {
      this.props.deleteLabel({ labelId: this.props.labelId })
    }
  }

  render() {
    const { closePopup, loading, caption } = this.props
    const { deleteConfirmation } = this.state
    const canDelete = this.state.deleteConfirmation === 'delete' || this.state.deleteConfirmation === 'DELETE'
    const shouldDisableButton = !canDelete || loading

    return (
      <Popup
        className="DeleteLabelPopup"
        closePopup={closePopup}
        isDark
      >
        <div className="DeleteLabelPopup__title">Delete the label</div>
        <p className="DeleteLabelPopup__description">
          <AttentionIcon className="DeleteLabelPopup__description__icon" />
          Warning, you will delete the label<br />
          “{caption}”.
        </p>
        <p className="DeleteLabelPopup__description">
          Type “delete” below to validate this action.
        </p>

        <input className="DeleteLabelPopup__confirmation"
          placeholder="delete"
          value={deleteConfirmation}
          onChange={this.changeDeleteCofirmation}
        />

        <Button
          color="primary-dark"
          caption="Confirm the action"
          onClick={this.handleConfirm}
          showSpinner={loading}
          disabled={shouldDisableButton}
        />
      </Popup>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { loading, labelId, caption } = state.deleteLabel

  return {
    loading,
    labelId,
    caption
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    deleteLabel,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteLabelPopup)
