import React, { useState } from 'react'
import classnames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import { UseFormRegisterReturn } from 'react-hook-form'

import './Checkbox.scss'

interface CheckboxProps {
  id?: string
  defaultChecked?: boolean
  checked?: boolean
  label?: string | JSX.Element
  disabled?: boolean
  registerReturn?: UseFormRegisterReturn<any>
  onChange?(checked: boolean): void
  tooltipForDisabledLine1?: string
  tooltipForDisabledLine2?: string
  tooltipForEnabledLine1?: string
  tooltipForEnabledLine2?: string
  labelIcon?: JSX.Element
}

const Checkbox: React.FC<CheckboxProps> = props => {
  const {
    id,
    label,
    checked,
    defaultChecked,
    disabled,
    tooltipForDisabledLine1,
    tooltipForDisabledLine2,
    tooltipForEnabledLine1,
    tooltipForEnabledLine2,
    labelIcon,
    registerReturn,
  } = props

  const checkboxUuid = uuidv4()

  const [checkmarkActive, setCheckmarkActive] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [infoTimeout, setInfoTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled || !props.onChange) { return }

    props.onChange((e.target as HTMLInputElement).checked)
  }

  const handleActiveChangeCheckmark = () => {
    if (disabled) { return }

    setCheckmarkActive(!checkmarkActive)
  }

  const toggleShowInfo = () => {
    const shouldShowInfo =
      disabled && (tooltipForDisabledLine1 || tooltipForDisabledLine2) ||
      !disabled && (tooltipForEnabledLine1 || tooltipForEnabledLine2)

    if (!shouldShowInfo) { return }

    if (infoTimeout) {
      setShowInfo(false)
      clearTimeout(infoTimeout)
      setInfoTimeout(null)
    } else {
      const newInfoTimeout = setTimeout(() => {
        setShowInfo(true)
      }, window.EditorSources.tooltipDelay)
      setInfoTimeout(newInfoTimeout)
    }
  }

  const shouldShowInfoForDisabled = disabled && (tooltipForDisabledLine1 || tooltipForDisabledLine2) && showInfo
  const shouldShowInfoForEnabled = !disabled && (tooltipForEnabledLine1 || tooltipForEnabledLine2) && showInfo

  return (
    <div
      className={classnames(
        'Checkbox',
        { 'Checkbox__disabled': disabled }
      )}
      onMouseEnter={toggleShowInfo}
      onMouseLeave={toggleShowInfo}
    >
      <label className="Checkbox__label" htmlFor={id ?? `checkbox-${checkboxUuid}`}>
        <input
          type="checkbox"
          id={id ?? `checkbox-${checkboxUuid}`}
          name={name ?? `checkbox-${checkboxUuid}`}
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={handleChange}
          {...registerReturn}
        />
        <span className={classnames(
            'Checkbox__checkmark',
            { 'Checkbox__checkmark-active': checkmarkActive }
          )}
        />
        {label && (
          <React.Fragment>
            <span
              className="Checkbox__label-text"
              onMouseEnter={handleActiveChangeCheckmark}
              onMouseLeave={handleActiveChangeCheckmark}
            >
              {label}
            </span>
            {labelIcon && labelIcon}
          </React.Fragment>
        )}
      </label>

      {shouldShowInfoForDisabled && (
        <div className="Checkbox__tooltip">
          {tooltipForDisabledLine1}
          <br/>
          {tooltipForDisabledLine2}
        </div>
      )}

      {shouldShowInfoForEnabled && (
        <div className="Checkbox__tooltip">
          {tooltipForEnabledLine1}
          <br/>
          {tooltipForEnabledLine2}
        </div>
      )}
    </div>
  )
}

export default Checkbox
