import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute: React.FC = () => {
  const isAuthorized = window.Checksub.cookies.get('authorized')
  const onboardingState = window.Checksub.cookies.get('onboarding')

  if (!isAuthorized) {
    return <Navigate to="/users/sign_in" />
  }

  const onboardingPage = window.location.pathname === '/onboarding'
  const canRenderNonOnboardingPages = onboardingState === 'processed'

  if (onboardingPage && canRenderNonOnboardingPages) {
    return <Navigate to="/onboarding/check_email" />
  }

  return (
    <Outlet />
  )
}

export default PrivateRoute