import {
  ThemeActionTypes,
  TThemeActionTypes,
  ISetThemePayload,
  IFetchThemesSuccessResponseBody,
} from './types'

export const setTheme = (payload: ISetThemePayload): TThemeActionTypes => ({
  type: ThemeActionTypes.SET_THEME,
  payload
})

export const openSetThemePopup = (): TThemeActionTypes => ({
  type: ThemeActionTypes.OPEN_SET_THEME_POPUP,
})

export const closeSetThemePopup = (): TThemeActionTypes => ({
  type: ThemeActionTypes.CLOSE_SET_THEME_POPUP,
})

export const fetchThemes = () => ({
  type: ThemeActionTypes.FETCH_THEMES,
})

export const fetchThemesSuccess = (payload: IFetchThemesSuccessResponseBody) => ({
  type: ThemeActionTypes.FETCH_THEMES_SUCCESS,
  payload,
})

export const fetchThemesFailure = () => ({
  type: ThemeActionTypes.FETCH_THEMES_FAILURE,
})
