import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Header from '@SiteContainers/Header'
import ProjectVersionSettingsFormTts from '@SiteComponents/ProjectVersionSettingsFormTts'

import {
  getUserInfo
} from '@SiteModules/User/actions'

import {
  fetchProjectVersionSettingsTts,
  setupTts,
} from './actions'

import {
  fetchTermbases,
} from '@SiteModules/Termbase/actions'

import {
  getTermbaseNames,
} from '@SiteModules/Termbase/selectors'

import {
  IVoiceAttributes,
} from '@EditorContainers/Settings/types'

import {
  ISetupTtsPayload,
} from './types'

import './ProjectVersionSettingsTts.scss'

interface ProjectVersionSettingsTtsProps {
  readonly isNewUser: boolean
  readonly loading: boolean
  readonly dubbingLanguages: string[]
  readonly dubbingLanguagesCodes: string[]
  readonly voices: IVoiceAttributes[]
  readonly lastUsedTtsProjectVersionLanguage?: string
  readonly availableTotalCredits: number
  readonly subscriptionAllowsVoice: boolean
  readonly termbaseNames: string[]
  readonly lastUsedTermbaseName?: string
  fetchProjectVersionSettingsTts(): void
  fetchTermbases(): void
  getUserInfo(): void
  setupTts(payload: ISetupTtsPayload): void
}

interface ProjectVersionSettingsTtsState {
  hasLoaded: boolean
}

class ProjectVersionSettingsTts extends React.Component<ProjectVersionSettingsTtsProps, ProjectVersionSettingsTtsState> {
  constructor(props: ProjectVersionSettingsTtsProps) {
    super(props)

    this.state = {
      hasLoaded: false
    }
  }

  componentDidMount() {
    this.props.fetchProjectVersionSettingsTts()
    this.props.fetchTermbases()
    this.props.getUserInfo()
  }

  componentDidUpdate(prevProps: ProjectVersionSettingsTtsProps) {
    if (prevProps.loading && !this.props.loading) {
      this.setState({ hasLoaded: true })
    }
  }

  render() {
    const shouldNotLinkToDashboard = this.props.isNewUser

    const {
      loading,
      voices,
      dubbingLanguages,
      dubbingLanguagesCodes,
      lastUsedTtsProjectVersionLanguage,
      availableTotalCredits,
      subscriptionAllowsVoice,
      termbaseNames,
      lastUsedTermbaseName,
    } = this.props

    return (
      <div className="ProjectVersionSettingsTts">
        <Header
          shouldNotLinkToDashboard={shouldNotLinkToDashboard}
        />
        <ProjectVersionSettingsFormTts
          voices={voices}
          hasLoaded={this.state.hasLoaded}
          showSpinner={loading}
          disableButton={loading}
          dubbingLanguages={dubbingLanguages}
          dubbingLanguagesCodes={dubbingLanguagesCodes}
          lastUsedTtsProjectVersionLanguage={lastUsedTtsProjectVersionLanguage}
          setupTts={this.props.setupTts}
          availableTotalCredits={availableTotalCredits}
          subscriptionAllowsVoice={subscriptionAllowsVoice}
          termbaseNames={termbaseNames}
          lastUsedTermbaseName={lastUsedTermbaseName}
        />
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { user, projectVersionSettingsTts, termbases } = state

  return {
    isNewUser: user.user.attributes.isNewUser,
    lastUsedTtsProjectVersionLanguage: user.user.attributes.lastUsedTtsProjectVersionLanguage,
    loading: projectVersionSettingsTts.loading || user.loading || termbases.loadingTermbases,
    dubbingLanguages: projectVersionSettingsTts.dubbingLanguages,
    dubbingLanguagesCodes: projectVersionSettingsTts.dubbingLanguagesCodes,
    voices: projectVersionSettingsTts.voices,
    availableTotalCredits: projectVersionSettingsTts.availableTotalCredits,
    subscriptionAllowsVoice: projectVersionSettingsTts.subscriptionAllowsVoice,
    termbaseNames: getTermbaseNames(termbases),
    lastUsedTermbaseName: user.user.attributes.lastUsedTermbaseName,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    fetchProjectVersionSettingsTts,
    fetchTermbases,
    getUserInfo,
    setupTts,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectVersionSettingsTts)
