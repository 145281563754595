import React, { useEffect, useRef, useState } from 'react'
import { unstable_usePrompt } from 'react-router-dom'
import map from 'lodash/map'
import some from 'lodash/some'
import toastr from 'toastr'
import classnames from 'classnames'
import hasElementOrSomeParentTheClass from '@Utils/HasElementOrSomeParentTheClass'
import Parameters from './Parameters'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import WarningPopup from '@SiteComponents/WarningPopup'
import CheckboxV2 from '@EditorComponents/CheckboxV2'
import DeleteItem from '@Images/delete_customization_item.svg'
import EditItem from '@Images/edit_customization_item.svg'
import CancelEditing from '@Images/cancel_editing.svg'
import SaveInput from '@Images/save_input.svg'

import {
  IGuideline,
  IGuidelineAttributes,
  IGuidelineParameters,
  ICreateCustomizationPayload,
  ISaveCustomizationPayload,
} from '@SiteModules/Guideline/types'

import {
  IVocabulary,
} from '@SiteModules/Vocabulary/types'

import './CustomizationForm.scss'

interface CustomizationFormProps {
  readonly items: (IGuideline | IVocabulary)[]
  readonly placeholderText: string
  readonly contentEdited: boolean
  readonly parametersEdited?: boolean
  readonly activeItem: IGuideline | IVocabulary | null
  readonly editedContent: string
  readonly editedParameters?: IGuidelineParameters
  readonly editedName: string | null
  readonly isBusy: boolean
  readonly maxContentLength: number
  readonly maxNameLength: number
  readonly discardChangesPopupOpen: boolean
  readonly linkOffset?: number
  readonly isVocabulary?: boolean
  readonly defaultParameters?: IGuidelineParameters
  openDiscardChangesPopup(): void
  closeDiscardChangesPopup(): void
  setDelayedActionType(delayedActionType: string): void
  setDelayedActionItemIndex(delayedActionItemIndex: number | null): void
  setContentEdited(contentEdited: boolean): void
  setParametersEdited?(parametersEdited: boolean): void
  setActiveItem(activeItem: null | IGuideline | IVocabulary): void
  setEditedContent(editedContent: string): void
  setEditedParameters?(editedParameters: IGuidelineParameters): void
  setEditedName(editedName: string | null): void
  create(payload: ICreateCustomizationPayload): void
  save(payload: ISaveCustomizationPayload): void
  delayedAction(): void
}

const HEIGHT_OF_ITEM = 40 // px Must be the same as height of .CustomizationForm__item in CustomizationForm.scss
const MARGIN_BOTTOM_OF_ITEM = 16 // px Must be the same as margin-bottom of .CustomizationForm__item in CustomizationForm.scss

const CustomizationForm: React.FC<CustomizationFormProps> = props => {
  const {
    items,
    placeholderText,
    contentEdited,
    parametersEdited,
    activeItem,
    editedContent,
    editedParameters,
    editedName,
    linkOffset,
    isBusy,
    maxContentLength,
    maxNameLength,
    isVocabulary,
    discardChangesPopupOpen,
    defaultParameters,
  } = props

  const [deleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] = useState(false)

  const isGuideline = !isVocabulary
  const somethingHasBeenEdited = contentEdited || (typeof(parametersEdited) === 'boolean' && parametersEdited)
  const hasItems = items.length > 0
  const contentTooLong = isVocabulary ? some(editedContent.split('\n'), content => content.length > maxContentLength) : editedContent.length > maxContentLength
  const itemName = isVocabulary ? 'vocabulary list' : 'guideline'

  const editedNameRef = useRef(editedName)
  const activeItemRef = useRef(activeItem)
  const itemsRef = useRef(items)

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    editedNameRef.current = editedName
  }, [editedName])

  useEffect(() => {
    activeItemRef.current = activeItem
  }, [activeItem])

  useEffect(() => {
    itemsRef.current = items
  }, [items])

  const handleKeydown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter' || editedNameRef.current === null) { return }

    handleSaveEditedName()
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (editedNameRef.current === null) { return }

    if (e.target) {
      const element = e.target as HTMLElement
      if (hasElementOrSomeParentTheClass(element, 'CustomizationForm__name-input-wrapper') || hasElementOrSomeParentTheClass(element, 'CustomizationForm__button-wrapper-save-active')) {
        return
      } else {
        handleSaveEditedName()
      }
    }
  }

  const openDeleteConfirmationPopup = () => {
    setDeleteConfirmationPopupOpen(true)
  }

  const closeDeleteConfirmationPopup = () => {
    setDeleteConfirmationPopupOpen(false)

    props.setDelayedActionType('')
  }

  const changeItem = (item: IGuideline | IVocabulary | null) => {
    props.setActiveItem(item)
    let newEditedContent = ''
    if (item) {
      newEditedContent = isVocabulary ? (item as IVocabulary).attributes.utterances.join('\n') : (item as IGuideline).attributes.prompt
    }
    props.setEditedContent(newEditedContent)
    props.setContentEdited(false)

    if (isGuideline) {
      let newEditedParameters = defaultParameters as IGuidelineParameters

      if (item) {
        newEditedParameters = (item as IGuideline).attributes.parameters
      }

      props.setEditedParameters!(newEditedParameters)
      props.setParametersEdited!(false)
    }
  }

  const changeContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newEditedContent = (e.target as HTMLTextAreaElement).value
    props.setEditedContent(newEditedContent)

    const contentIsNew = !activeItem && newEditedContent.length > 0

    if (contentIsNew) {
      props.setContentEdited(true)
    } else {
      const contentHasChanged = isVocabulary
        ? !!activeItem && newEditedContent !== ((activeItem as IVocabulary).attributes.utterances || []).join('\n')
        : !!activeItem && newEditedContent !== (activeItem as IGuideline).attributes.prompt

      props.setContentEdited(contentHasChanged)

      props.setContentEdited(contentHasChanged)
    }
  }

  const startEditingName = () => {
    props.setEditedName(activeItem!.attributes.name)
  }

  const changeEditedName = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setEditedName((e.target as HTMLInputElement).value)
  }

  const cancelEditingName = () => {
    props.setEditedName(null)
  }

  const shouldContinueAfterNameCheck = (newEditedName: string) => {
    const nameAlreadyUsed = some(itemsRef.current, item => item.attributes.name === newEditedName)
    const nameTooLong = newEditedName.length > maxNameLength

    const canSaveName = !nameAlreadyUsed && !nameTooLong
    if (canSaveName) { return true }

    const message = nameAlreadyUsed ? 'Name already in use' : `Maximum name length is ${maxNameLength} characters`

    toastr.error(
      message,
      'Cannot save name',
    )

    return false
  }

  const handleSaveEditedName = () => {
    const trimmedEditedName = editedNameRef.current?.trim()

    const shouldSaveNameForActiveItem = !!trimmedEditedName && activeItemRef.current && trimmedEditedName !== activeItemRef.current.attributes.name

    if (shouldSaveNameForActiveItem) {
      if (shouldContinueAfterNameCheck(trimmedEditedName)) {
        const isDefault = isGuideline && (activeItemRef.current!.attributes as IGuidelineAttributes).isDefault

        props.save({
          id: activeItemRef.current!.id,
          name: trimmedEditedName,
          ...(isDefault ? { defaultGuideline: activeItemRef.current as IGuideline } : {})
        })
      }
    } else {
      cancelEditingName()
    }
  }

  const saveEverything = () => {
    const shouldSaveNameForActiveItem = editedName !== null && editedName.trim().length > 0 && activeItem && editedName.trim() !== activeItem.attributes.name

    if (shouldSaveNameForActiveItem) {
      const newEditedName = editedName.trim()

      if (shouldContinueAfterNameCheck(newEditedName)) {
        const isDefault = isGuideline && (activeItem.attributes as IGuidelineAttributes).isDefault

        props.save({
          id: activeItem.id,
          name: newEditedName,
          content: editedContent,
          ...(isGuideline ? { parameters: editedParameters } : {}),
          ...(isDefault ? { defaultGuideline: activeItem as IGuideline } : {})
        })
      }
    } else {
      if (activeItem) {
        const isDefault = isGuideline && (activeItem.attributes as IGuidelineAttributes).isDefault

        props.save({
          id: activeItem.id,
          content: editedContent,
          ...(isGuideline ? { parameters: editedParameters } : {}),
          ...(isDefault ? { defaultGuideline: activeItem as IGuideline } : {})
        })
      } else {
        props.create({
          content: editedContent,
          ...(isGuideline ? { parameters: editedParameters } : {}),
        })
      }
    }
  }

  const createNewItem = () => {
    if (somethingHasBeenEdited) {
      props.setDelayedActionType('createNewItem')
      props.openDiscardChangesPopup()
    } else {
      props.create({})
    }
  }

  const deleteItem = () => {
    props.setDelayedActionType('deleteItem')
    openDeleteConfirmationPopup()
  }

  const changeParameter = (name: string, value: number | boolean) => {
    if (!editedParameters || !isGuideline) { return }

    const newEditedParameters = { ...editedParameters, [name]: value }
    props.setEditedParameters!(newEditedParameters)

    const isNew = !activeItem && JSON.stringify(newEditedParameters) !== JSON.stringify(defaultParameters)
    const hasChanged = !!activeItem && JSON.stringify(newEditedParameters) !== JSON.stringify((activeItem as IGuideline).attributes.parameters)
    const hasEditedParameters = isNew || hasChanged

    props.setParametersEdited!(hasEditedParameters)
  }

  const changeAiCorrection = (value: boolean) => {
    changeParameter('useAiCorrection', value)
  }

  const renderNameInput = () => {
    return (
      <div className="CustomizationForm__name-input-wrapper">
        <input
          className="CustomizationForm__name-input"
          value={editedName as string}
          onChange={changeEditedName}
          autoFocus
        />
        <SaveInput
          className="CustomizationForm__name-save"
          onClick={handleSaveEditedName}
        />
        <CancelEditing
          className="CustomizationForm__name-cancel"
          onClick={cancelEditingName}
        />
      </div>
    )
  }

  const renderNameAndActions = (item: IGuideline | IVocabulary) => {
    const isActive = activeItem && activeItem.id === item.id

    return (
      <React.Fragment>
        <span
          className="CustomizationForm__item-name"
        >
          {item.attributes.name}
        </span>
        {isActive && (
          <DeleteItem
            className="CustomizationForm__item-delete"
            onClick={deleteItem}
          />
        )}
        {isActive && (
          <EditItem
            className="CustomizationForm__item-edit"
            onClick={startEditingName}
          />
        )}
      </React.Fragment>
    )
  }

  const itemsToRender = map(items, (item, index) => {
    const isActive = activeItem === item

    const handleChangeItem = () => {
      if (isBusy) { return }

      if (!activeItem || item.id !== activeItem.id) {
        if (somethingHasBeenEdited) {
          props.setDelayedActionType('changeItem')
          props.setDelayedActionItemIndex(item.id)
          props.openDiscardChangesPopup()
        } else {
          changeItem(item)
        }
      }
    }

    return (
      <li
        key={index}
        className={classnames(
          'CustomizationForm__item', {
            'CustomizationForm__item-active': isActive
          }
        )}
        onClick={handleChangeItem}
      >
        {editedName && isActive ? renderNameInput() : renderNameAndActions(item)}
      </li>
    )
  })

  const renderItems = () => {
    const activeItemIndex = activeItem ? items.indexOf(activeItem) : 0
    const topValueOfActiveLine = activeItemIndex * (HEIGHT_OF_ITEM + MARGIN_BOTTOM_OF_ITEM)

    return (
      <div className="CustomizationForm__items-wrapper">
        <ul className="CustomizationForm__items">
          {itemsToRender}
        </ul>
        {activeItem && (
          <div
            className="CustomizationForm__items-active-line"
            style={{top: `${topValueOfActiveLine}px`}}
          />
        )}
      </div>
    )
  }

  const renderAiCorrectionCheckbox = () => {
    return (
      <div className="CustomizationForm__ai-correction-checkbox">
        <CheckboxV2
          checked={!!editedParameters?.useAiCorrection || false}
          label="Improve grammar, spelling and punctuation with AI"
          onChange={changeAiCorrection}
        />
      </div>
    )
  }

  const renderTextArea = () => {
    const renderingPlaceholderText = editedContent.length === 0
    const shouldRenderLink = renderingPlaceholderText && typeof(linkOffset) === 'number'

    return (
      <div className="CustomizationForm__textarea-wrapper">
        <textarea
          value={editedContent}
          placeholder={placeholderText}
          className="CustomizationForm__textarea"
          onChange={changeContent}
        />
        {shouldRenderLink && (
          <a
            href="https://support.checksub.com/get-started/quick-start/how-to-use-a-guideline"
            className="CustomizationForm__textarea-link"
            style={{ 'left' : `${linkOffset}px` }}
            target="_blank"
          >
            example.
          </a>
        )}
      </div>
    )
  }

  const renderWarningContentTooLong = () => {
    return(
      <div className="CustomizationForm__warning">
        {`Input must not be longer than ${maxContentLength} characters`}
      </div>
    )
  }

  const renderParameters = () => {
    return (
      <Parameters
        parametersEdited={parametersEdited as boolean}
        editedParameters={editedParameters as IGuidelineParameters}
        changeParameter={changeParameter}
      />
    )
  }

  const renderSaveButton = () => {
    const hasOnlyDefaultValuesForGuidelines = isGuideline && items.length === 0 // To allow to save the default parameters and an empty prompt
    const shouldDisableSaveButton = (!somethingHasBeenEdited || contentTooLong || isBusy) && !hasOnlyDefaultValuesForGuidelines

    return (
      <div className={classnames(
        'CustomizationForm__button-wrapper-save', {
          'CustomizationForm__button-wrapper-save-active': !shouldDisableSaveButton
        }
      )}>
        <ButtonNewV2
          styleType="brand-primary"
          caption="Save"
          size="small"
          disabled={shouldDisableSaveButton}
          onClick={saveEverything}
        />
      </div>
    )
  }

  const renderLeftPart = () => {
    return (
      <div className="CustomizationForm__left">
        {renderItems()}
        <ButtonNewV2
          styleType="brand-tertiary"
          caption="Create New"
          size="small"
          onClick={createNewItem}
        />
      </div>
    )
  }

  const renderRightPart = () => {
    const shouldRenderParameters = isGuideline

    return (
      <div className={classnames(
        'CustomizationForm__right', {
          'CustomizationForm__right-with-parameters': shouldRenderParameters
        }
      )}>
        {shouldRenderParameters && renderAiCorrectionCheckbox()}
        {renderTextArea()}
        {contentTooLong && renderWarningContentTooLong()}
        {shouldRenderParameters && renderParameters()}
        {renderSaveButton()}
      </div>
    )
  }

  const renderDiscardChangesPopup = () => {
    return (
      <WarningPopup
        closePopup={props.closeDiscardChangesPopup}
        delayedAction={props.delayedAction}
        header="Unsaved changes"
        description={`You have unsaved changes to the current ${itemName}. Leaving it now will discard them.`}
        captionButton1="Continue Editing"
        captionButton2="Discard"
      />
    )
  }

  const renderDeleteConfirmationPopup = () => {
    return (
      <WarningPopup
        closePopup={closeDeleteConfirmationPopup}
        delayedAction={props.delayedAction}
        header={`Deleting the ${itemName}`}
        description={`Confirm that you want to delete the ${itemName}. This action cannot be undone.`}
        captionButton1="Abort"
        captionButton2="Delete"
        useEnterToConfirm
      />
    )
  }

  unstable_usePrompt({
    message: 'Are you sure you want to leave?',
    when: somethingHasBeenEdited || false
  })

  return (
    <div className="CustomizationForm">
      {hasItems && renderLeftPart()}
      {renderRightPart()}
      {discardChangesPopupOpen && renderDiscardChangesPopup()}
      {deleteConfirmationPopupOpen && renderDeleteConfirmationPopup()}
    </div>
  )
}

export default CustomizationForm
