import React from 'react'
import DropdownList from 'react-widgets/DropdownList'

import './Guidelines.scss'

interface GuidelinesProps {
  readonly guidelineNames: string[]
  readonly selectedGuidelineName: string
  setSelectedGuidelineName(newGuidelineName: string): void
}

const Guidelines: React.FC<GuidelinesProps> = props => {
  const {
    guidelineNames,
    selectedGuidelineName,
    setSelectedGuidelineName,
  } = props

  const guidelinesPresent = guidelineNames.length > 0

  const guidelineNamesWithOptionForNoList = ['None'].concat(guidelineNames)

  return (
    <div className="ProjectVersionSettingsForm__formfield Guidelines__formfield">
      {guidelinesPresent ? (
        <React.Fragment>
          <label className="ProjectVersionSettingsForm__label" htmlFor="guideline">
            Guideline:
          </label>
          <DropdownList
            placeholder="Select a guideline..."
            data={guidelineNamesWithOptionForNoList}
            id="guidelineName"
            name="guidelineName"
            value={selectedGuidelineName}
            onChange={setSelectedGuidelineName}
          />
        </React.Fragment>
      ) : (
        <div className="Guidelines__create-list">
          <a href="/customizations/guidelines">Create a guideline</a>
        </div>
      )}
    </div>
  )
}

export default Guidelines
