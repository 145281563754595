import React from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'

import './ConfirmationButton.scss'

interface ConfirmationButtonProps {
  readonly showSpinner: boolean
  readonly disableButton: boolean
  readonly shouldRenderLinkToSubscription: boolean
  readonly defaultCaption?: string
  readonly icon?: JSX.Element
  readonly iconRight?: boolean
  readonly generateShortenedProjectVersion?: boolean
}

const ConfirmationButton: React.FC<ConfirmationButtonProps> = props => {
  const {
    showSpinner,
    disableButton,
    shouldRenderLinkToSubscription,
    defaultCaption,
    iconRight,
    icon,
  } = props



  const buttonCaption = defaultCaption ?? 'Confirm'


  const forwardToSubscription = () => {
    window.location.href = '/subscriptions'
  }

  return (
    <div className="ConfirmationButton">
      {shouldRenderLinkToSubscription ? (
        <ButtonNewV2
          styleType="brand-primary"
          size="large"
          caption="Upgrade my plan"
          showSpinner={showSpinner}
          disabled={showSpinner}
          onClick={forwardToSubscription}
          icon={icon}
          iconRight={iconRight}
        />
      ) : (
        <ButtonNewV2
          type="submit"
          styleType="brand-primary"
          size="large"
          caption={buttonCaption}
          showSpinner={showSpinner}
          disabled={disableButton}
          icon={icon}
          iconRight={iconRight}
        />
      )}
    </div>
  )
}

export default ConfirmationButton
