import React from 'react'
import DropdownList from 'react-widgets/DropdownList'

import './Termbases.scss'

interface TermbasesProps {
  readonly termbaseNames: string[]
  readonly selectedTermbaseName: string
  setSelectedTermbaseName(newTermbaseName: string): void
}

const Termbases: React.FC<TermbasesProps> = props => {
  const {
    termbaseNames,
    selectedTermbaseName,
    setSelectedTermbaseName,
  } = props

  const termbasesPresent = termbaseNames.length > 0

  const termbaseNamesWithOptionForNoList = ['None'].concat(termbaseNames)

  return (
    <div className="ProjectVersionSettingsForm__formfield Termbases__formfield">
      {termbasesPresent ? (
        <React.Fragment>
          <label className="ProjectVersionSettingsForm__label" htmlFor="termbase">
            Term base:
          </label>
          <DropdownList
            placeholder="Select a term base..."
            data={termbaseNamesWithOptionForNoList}
            id="termbaseName"
            name="termbaseName"
            value={selectedTermbaseName}
            onChange={setSelectedTermbaseName}
          />
        </React.Fragment>
      ) : (
        <div className="Termbases__create-list">
          <a href="/customizations/termbases">Create a term base</a>
        </div>
      )}
    </div>
  )
}

export default Termbases
