import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import some from 'lodash/some'
import sanitizeUrlsString from '@Utils/SanitizeUrlsString'
import modifyUrls from '@Utils/ModifyUrls'
import validateUrls from '@Utils/ValidateUrls'
import toastr from 'toastr'
import { ISubmitUrlsPayload } from '@SiteModules/Element/types'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import Confirm from '@Images/confirm.svg'

interface SubmitUrlsAreaProps {
  readonly uploading: boolean
  readonly remainingElementStorageSpace?: number
  readonly maxNumberOfUrlsToSubmit: number
  readonly placeholder: JSX.Element
  readonly buttonCaption?: string
  submitUrls(payload: ISubmitUrlsPayload): void
  setWarningMessages(newWarningMessages: string[]): void
  setShouldShowRemainingDiskSpaceWarning?(newShouldShowRemainingDiskspaceWarning: boolean): void
}

type Form = {
  urlsString: string
}

const GOOGLE_DRIVE_URL_PATTERNS = ['docs.google.com', 'drive.google.com']

const SubmitUrlsArea: React.FC<SubmitUrlsAreaProps> = props => {
  const { register, handleSubmit } = useForm<Form>()

  const [urls, setUrls] = useState<string[]>([])
  const [urlsCorrect, setUrlsCorrect] = useState<boolean>(true)

  const tooManyUrls = urls.length > props.maxNumberOfUrlsToSubmit && urlsCorrect
  const googleDriveUsed = some(urls, url => some(GOOGLE_DRIVE_URL_PATTERNS, pattern => url.includes(pattern)))
  const noSpaceLeft = false // typeof(props.remainingElementStorageSpace) === 'number' && props.remainingElementStorageSpace <= 0

  const onSubmit = handleSubmit(({ urlsString }) => {
    const modifiedUrls = modifyUrls(sanitizeUrlsString(urlsString))
    if (modifiedUrls.includes(undefined)) {
      toastr.error(
        'URLs are not valid.',
        'URL(s) could not be submitted',
      )
    } else {
      const urlsToSubmit = modifiedUrls as string[]
      props.submitUrls({ urls: urlsToSubmit })
    }
  })

  const changeUrls = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const sanitizedUrls = sanitizeUrlsString((e.target as HTMLTextAreaElement).value) || []
    const newUrls = sanitizedUrls
    setUrls(newUrls)
    const newUrlsCorrect = validateUrls(sanitizedUrls)
    setUrlsCorrect(newUrlsCorrect)
    const newWarningMessages = []

    const googleDriveUsedInNewUrls = some(newUrls, url => some(GOOGLE_DRIVE_URL_PATTERNS, pattern => url.includes(pattern)))

    if (googleDriveUsedInNewUrls) {
      newWarningMessages.push('Unfortunately, we cannot process links to google drive')
    }

    const tooManyNewUrls = newUrls.length > props.maxNumberOfUrlsToSubmit && urlsCorrect

    if (tooManyNewUrls) {
      newWarningMessages.push(`Maximum ${props.maxNumberOfUrlsToSubmit} URL(s) can be processed together`)
    }

    props.setWarningMessages(newWarningMessages)

    if (noSpaceLeft && props.setShouldShowRemainingDiskSpaceWarning) {
      props.setShouldShowRemainingDiskSpaceWarning(true)
    }
  }

  const renderTextArea = () => {
    const shouldShowPlaceholder = urls.length === 0

    return (
      <div className="Upload__submit-urls-area-textarea-wrapper">
        {shouldShowPlaceholder && (
          <div className="Upload__submit-urls-area-placeholder">
            {props.placeholder}
          </div>
        )}
        <textarea
          id="urlsString"
          className="Upload__submit-urls-area-textarea"
          {...register('urlsString', { required: true, maxLength: props.maxNumberOfUrlsToSubmit * 10000, onChange: changeUrls })} // the max length of a url is arbitrary. I assumed it shouldn't exceed 10000 characters
        />
      </div>
    )
  }

  const renderSubmitButton = () => {
    return (
      <div className="Upload__submit-button">
        <ButtonNewV2
          type="submit"
          size="large"
          styleType="brand-primary"
          caption={props.buttonCaption ? props.buttonCaption : 'Confirm'}
          icon={<Confirm/>}
          iconRight
        />
      </div>
    )
  }

  const shouldShowSubmitButton =
    !props.uploading &&
    urls.length > 0 &&
    urlsCorrect &&
    !tooManyUrls &&
    !googleDriveUsed &&
    !noSpaceLeft

  return (
    <div className="Upload__submit-urls-area">
      <form className="Upload__submit-urls-area-form" onSubmit={onSubmit} >
        {renderTextArea()}
        {shouldShowSubmitButton && renderSubmitButton()}
      </form>
    </div>
  )
}

export default SubmitUrlsArea
