import React, { useState } from 'react'
import intersectionBy from 'lodash/intersectionBy'
import filter from 'lodash/filter'
import capitalize from 'lodash/capitalize'
import classnames from 'classnames'
import Add from '@Images/add_icon_grey.svg'
import More from '@Images/more_icon.svg'
import Balloon from '@SiteComponents/Balloon/Balloon'
import Subtitle from './Subtitle'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import useClickOutsideListener from '@Utils/UseClickOutsideListener'

import {
  ISubtitle,
  IProjectVersionTableRow,
  IDeleteProjectVersionPopup,
  IOpenImportSrtPayload,
  IOpenExportationPayload,
  IOpenRateSubtitlePayload,
  IChangeSelectedProjectVersionsPayload,
} from './types'

interface ProjectVersionProps {
  readonly row: IProjectVersionTableRow
  readonly selected: boolean
  readonly subtitles: ISubtitle[]
  readonly hasCurrentSubscription: boolean
  readonly isShortened: boolean
  openExternalTokenPopup(subtitleId: number): void
  openImportSrtPopup(payload: IOpenImportSrtPayload): void
  openExportationPopup(payload: IOpenExportationPayload): void
  openRateSubtitlePopup(payload: IOpenRateSubtitlePayload): void
  openDeleteProjectVersionPopup(payload: IDeleteProjectVersionPopup): void
  changeSelectedProjectVersions(payload: IChangeSelectedProjectVersionsPayload): void
}

const ProjectVersion: React.FC<ProjectVersionProps> = props => {
  const [openBalloon, setOpenBalloon] = useState<boolean>(false)
  const [showSubtitles, setShowSubtitles] = useState<boolean>(false)
  const [shouldShowActionTools, setShouldShowActionTools] = useState<boolean>(false)
  const [showInfoForAdding, setShowInfoForAdding] = useState<boolean>(false)
  const [showInfoForId, setShowInfoForId] = useState<boolean>(false)
  const [showInfoForTitle, setShowInfoForTitle] = useState<boolean>(false)
  const [infoForAddingTimeout, setInfoForAddingTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)
  const [infoForIdTimeout, setInfoForIdTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)
  const [infoForTitleTimeout, setInfoForTitleTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const { row, subtitles, isShortened } = props

  const durationNotYetDetermined = row.duration === '00:00' && row.status === 'downloading'

  useClickOutsideListener(`ProjectVersions__more-icon-wrapper-${row.id}`, setOpenBalloon, openBalloon)

  const rowStatus = !durationNotYetDetermined && row.status === 'downloading' ? '' : row.status // Downloading is only shown as a status if the project needs to be blocked because of an undetermined duration
  const projectVersionStatus = rowStatus ? capitalize(rowStatus.replace('_', ' ')) : null

  const projectVersionStatusClasses = `ProjectVersions__status-badge ${rowStatus}`

  const projectVersionSubtitles = intersectionBy(subtitles, row.subtitles, 'id')
  const subtitlesCount = projectVersionSubtitles.length
  const hasNoSubtitles = subtitlesCount === 0
  const hasAssets = row.hasAssets
  const transcriptionSubtitle = filter(projectVersionSubtitles, subtitle => subtitle.attributes.transcription === true)[0]
  const translationSubtitles = filter(projectVersionSubtitles, subtitle => subtitle.attributes.transcription === false)

  const shouldAllowAddingSubtitleLanguages = !row.isDisabled && !row.belongsToManualProject && hasAssets && !row.belongsToTtsProject && !row.isShortened && !durationNotYetDetermined

  const toggleShowingSubtitles = () => {
    if (!subtitlesCount) { return }
    setShowSubtitles(!showSubtitles)
  }

  const toggleShowInfoForAdding = () => {
    if (infoForAddingTimeout) {
      setShowInfoForAdding(false)
      clearTimeout(infoForAddingTimeout)
      setInfoForAddingTimeout(null)
    } else {
      const newInfoForAddingTimeout = setTimeout(() => {
        setShowInfoForAdding(true)
      }, window.Checksub.tooltipDelay)
      setInfoForAddingTimeout(newInfoForAddingTimeout)
    }
  }

  const toggleShowInfoForId = () => {
    if (infoForIdTimeout) {
      setShowInfoForId(false)
      clearTimeout(infoForIdTimeout)
      setInfoForIdTimeout(null)
    } else {
      const newInfoForIdTimeout = setTimeout(() => {
        setShowInfoForId(true)
      }, window.Checksub.tooltipDelay)
      setInfoForIdTimeout(newInfoForIdTimeout)
    }
  }

  const toggleShowInfoForTitle = () => {
    if (infoForTitleTimeout) {
      setShowInfoForTitle(false)
      clearTimeout(infoForTitleTimeout)
      setInfoForTitleTimeout(null)
    } else {
      const newInfoForTitleTimeout = setTimeout(() => {
        setShowInfoForTitle(true)
      }, window.Checksub.tooltipDelay)
      setInfoForTitleTimeout(newInfoForTitleTimeout)
    }
  }

  const showActionTools = () => {
    setShouldShowActionTools(true)
  }

  const hideActionTools = () => {
    setShouldShowActionTools(false)
  }

  const toggleShowingBalloon = () => {
    setOpenBalloon(!openBalloon)
  }

  const closeBalloon = () => {
    setOpenBalloon(false)
  }

  const openProjectVersionSettings = () => {
    window.location.href = `/setup?projectVersionIds=${row.id}`
  }

  const openDeleteProjectVersionPopup = () => {
    const payload = { projectVersionIds: [row.id] }
    props.openDeleteProjectVersionPopup(payload)
    setOpenBalloon(false)
  }

  const openExternalTokenPopup = (subtitleId: number) => {
    props.openExternalTokenPopup(subtitleId)
  }

  const openExportationPopup = (subtitleId: number) => {
    const payload = {
      subtitleIds: [subtitleId]
    }
    props.openExportationPopup(payload)
  }

  const openRateSubtitlePopup = (subtitleId: number) => {
    const payload = { subtitleId }
    props.openRateSubtitlePopup(payload)
  }

  const openImportSrtPopup = (subtitleId: number) => {
    const payload = { projectVersionId: row.id, subtitleId }
    props.openImportSrtPopup(payload)
  }

  const handleProjectVersionSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const payload = { projectVersionId: row.id, selectProjectVersion: (e.target as HTMLInputElement).checked }

    props.changeSelectedProjectVersions(payload)
  }

  const handleMouseEnterLength = () => {
    showActionTools()
    toggleShowInfoForId()
  }

  const handleMouseLeaveLength = () => {
    hideActionTools()
    toggleShowInfoForId()
  }

  const handleMouseEnterTitle = () => {
    showActionTools()
    toggleShowInfoForTitle()
  }

  const handleMouseLeaveTitle = () => {
    hideActionTools()
    toggleShowInfoForTitle()
  }

  const shouldShowColoredThumbnail = row.thumbnailUrl.includes('thumbnail-icon.png') || !row.thumbnailUrl
  const indexOfFirstIntegerInId = row.id.search(/[0-9]/)
  const colorPicker = indexOfFirstIntegerInId === -1 ? 0 : row.id[indexOfFirstIntegerInId]
  const shouldShowSubtitlesButton = hasNoSubtitles && hasAssets && !durationNotYetDetermined
  const shouldShowLinkToEditor = row.subtitles.length === 0 && row.status !== 'downloading'

  const renderProjectVersion = () => {
    return(
      <tr className={classnames('ProjectVersions__table-row', {
          'ProjectVersions__table-row-with-subtitles': subtitlesCount > 0,
          'ProjectVersions__table-row-open': showSubtitles,
        })}
        onMouseLeave={closeBalloon}
      >
        <td className="ProjectVersions__table-cell ProjectVersions__table-cell-projectVersion ProjectVersions__thumbnail_medium">
          {row.isDisabled && (
            <div>
              <div className="ProjectVersions__table-row-overlay" />
              <p className="ProjectVersions__table-row-update-caption">
                Update your plan to keep projects longer than 30 days.
              </p>
            </div>
          )}
          <div className="ProjectVersion__table-cell-wrapper">
            <label className="ProjectVersions__table-cell-checkbox" htmlFor={`projectVersionSelectCheckbox-${row.id}`}>
              <input
                id={`projectVersionSelectCheckbox-${row.id}`}
                name="projectVersionSelectCheckbox"
                type="checkbox"
                onChange={handleProjectVersionSelection}
                checked={props.selected}
              />
              <span className="ProjectVersions__table-checkmark" />
            </label>
            <div className="ProjectVersions__thumbnail-wrapper" onClick={toggleShowingSubtitles} onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
              {shouldShowColoredThumbnail ? (
                <span className={`ProjectVersions__thumbnail-placeholder ProjectVersions__thumbnail-placeholder${colorPicker}`}/>
              ) : (
                <img className="ProjectVersions__thumbnail" src={row.thumbnailUrl} />
              )}
            </div>
          </div>
        </td>
        <td className="ProjectVersions__table-cell ProjectVersions__table-cell-projectVersion ProjectVersions__title" onClick={toggleShowingSubtitles} onMouseEnter={handleMouseEnterTitle} onMouseLeave={handleMouseLeaveTitle}>
          <p>
            {shouldShowLinkToEditor ? (
              <a href={`/projects/${row.projectId}/project_versions/${row.id}/?projectSettings=true`}>
                {row.title}
              </a>
            ) : (
              <React.Fragment>
                {row.title}
              </React.Fragment>
            )}
          </p>
          {showInfoForTitle &&
            <div className="ProjectVersionsList__tooltip ProjectVersions__title-tooltip">
              {row.title}
            </div>
          }
        </td>
        <td className="ProjectVersions__table-cell ProjectVersions__table-cell-projectVersion ProjectVersions__length" onClick={toggleShowingSubtitles} onMouseEnter={handleMouseEnterLength} onMouseLeave={handleMouseLeaveLength}>
          {row.duration}
          {showInfoForId &&
            <div className="ProjectVersionsList__tooltip ProjectVersions__id-tooltip">
              Project version id: {row.id.slice(0, 6)}
            </div>
          }
        </td>
        <td className="ProjectVersions__table-cell ProjectVersions__table-cell-projectVersion ProjectVersions__status" onClick={toggleShowingSubtitles} onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
          <span className={projectVersionStatusClasses}>{projectVersionStatus}</span>
        </td>
        <td className="ProjectVersions__table-cell ProjectVersions__table-cell-projectVersion ProjectVersions__language" onClick={toggleShowingSubtitles} onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
          <p>
            {row.language}
            {shouldShowSubtitlesButton && (
              <ButtonNewV2
                size="small"
                styleType="brand-primary"
                caption="Add subtitles"
                onClick={openProjectVersionSettings}
              />
            )}
          </p>
        </td>
        <td className="ProjectVersions__table-cell ProjectVersions__table-cell-projectVersion ProjectVersions__subtitlesCount" onClick={toggleShowingSubtitles} onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
          {subtitlesCount}
        </td>
        <td className="ProjectVersions__table-cell ProjectVersions__table-cell-projectVersion ProjectVersions__upload_on" onClick={toggleShowingSubtitles} onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
          {row.createdAt}
        </td>
        <td className="ProjectVersions__table-cell ProjectVersions__table-cell-projectVersion ProjectVersions__actions" onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
          {shouldShowActionTools && (
              <div className="ProjectVersions__actions-wrapper">
              {shouldAllowAddingSubtitleLanguages && (
                <div
                  className="ProjectVersions__add-icon"
                  onClick={openProjectVersionSettings}
                  onMouseEnter={toggleShowInfoForAdding}
                  onMouseLeave={toggleShowInfoForAdding}
                >
                  <Add className="ProjectVersions__add-icon-svg" />

                  {showInfoForAdding && (
                    <div className="ProjectVersionsList__tooltip ProjectVersions__adding-tooltip">
                      Create new language(s)
                    </div>
                  )}
                </div>
              )}

              <div
                className={`ProjectVersions__more-icon-wrapper ProjectVersions__more-icon-wrapper-${row.id} ${openBalloon ? 'ProjectVersions__more-icon-wrapper-active':''}`}
                onClick={toggleShowingBalloon}
              >
                <div className="ProjectVersions__more-icon">
                  <More />
                </div>

                {openBalloon && (
                  <div className="ProjectVersions__balloon-wrapper">
                    <Balloon>
                      {shouldAllowAddingSubtitleLanguages && (
                        <li className="Balloon__list__item" onClick={openProjectVersionSettings}>
                          Add a subtitle language
                        </li>
                      )}

                      <li className="Balloon__list__item" onClick={openDeleteProjectVersionPopup}>
                        Delete the project
                      </li>
                    </Balloon>
                  </div>
                )}
              </div>
            </div>
          )}
        </td>
      </tr>
    )
  }

  const renderSubtitles = () => {
    if (!showSubtitles) { return }
    const { hasCurrentSubscription } = props

    return(
      <tr>
        <td className="ProjectVersions__table-cell ProjectVersions__table-cell-subtitles" colSpan={8}>
          <table className="ProjectVersions__table Subtitles__table">
            <thead className="ProjectVersions__table-header Subtitles__table-header">
              <tr className="Subtitle-table-row">
                <th className="ProjectVersions__table-cell" />
                <th className="ProjectVersions__table-cell Subtitles__header-languages">Languages</th>
                <th className="ProjectVersions__table-cell Subtitles__header-status">Status</th>
                <th className="ProjectVersions__table-cell Subtitles__header-type">Type</th>
                <th className="ProjectVersions__table-cell">Creation Date</th>
                <th className="ProjectVersions__table-cell Subtitles__header-last-export">Last Export</th>
                <th className="ProjectVersions__table-cell">Export Date</th>
                <th className="ProjectVersions__table-cell" />
                <th className="ProjectVersions__table-cell" />
              </tr>
            </thead>

            <tbody className="ProjectVersions__table-body">
              <Subtitle
                key={transcriptionSubtitle.id}
                subtitle={transcriptionSubtitle}
                projectVersionId={row.id}
                openImportSrtPopup={openImportSrtPopup}
                openExternalTokenPopup={openExternalTokenPopup}
                openExportationPopup={openExportationPopup}
                openRateSubtitlePopup={openRateSubtitlePopup}
                isDisabled={row.isDisabled}
                hasCurrentSubscription={hasCurrentSubscription}
                projectVersionIsShortened={isShortened}
              />
              {translationSubtitles.map((subtitle) => {
                return(
                  <Subtitle
                    key={subtitle.id}
                    subtitle={subtitle}
                    projectVersionId={row.id}
                    openImportSrtPopup={openImportSrtPopup}
                    openExternalTokenPopup={openExternalTokenPopup}
                    openExportationPopup={openExportationPopup}
                    openRateSubtitlePopup={openRateSubtitlePopup}
                    isDisabled={row.isDisabled}
                    hasCurrentSubscription={hasCurrentSubscription}
                    projectVersionIsShortened={isShortened}
                  />
                )
              })}
            </tbody>
          </table>
        </td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      {renderProjectVersion()}
      {renderSubtitles()}
    </React.Fragment>
  )
}

export default ProjectVersion
