import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { Navigate } from 'react-router-dom'

import Header from '@SiteContainers/Header'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import AssetsTable from '@SiteContainers/AssetsTable'
import ChannelsTable from '@SiteContainers/ChannelsTable'
import DashboardMenu from '@SiteContainers/DashboardMenu'
import ImportSrtPopup from '@SiteContainers/ImportSrtPopup'
import ExportationPopup from '@SiteContainers/ExportationPopup'
import CreateLabelPopup from '@SiteContainers/CreateLabelPopup'
import DashboardSidebar from '@SiteContainers/DashboardSidebar'
import RateSubtitlePopup from '@SiteContainers/RateSubtitlePopup'
import ExternalTokenPopup from '@SiteContainers/ExternalTokenPopup'
import ProjectVersionsList from '@SiteContainers/ProjectVersionsList'
import TableHeaderContainer from '@SiteContainers/TableHeaderContainer'
import RenameLabelPopup from '@SiteContainers/RenameLabelPopup/RenameLabelPopup'
import DeleteLabelPopup from '@SiteContainers/DeleteLabelPopup/DeleteLabelPopup'
import DeleteElementPopup from '@SiteContainers/DeleteElementPopup/DeleteElementPopup'
import DeleteProjectVersionPopup from '@SiteContainers/DeleteProjectVersionPopup/DeleteProjectVersionPopup'
import PageLoader from '@EditorComponents/PageLoader'

import showConfetti from '@Utils/ShowConfettiAnimation'

import { IApplicationState } from '../../rootReducer'

import {
  IProjectVersion,
} from '@SiteContainers/ProjectVersionsList/types'

import {
  closePopup,
  fetchProjectVersions,
} from '@SiteContainers/ProjectVersionsList/actions'

import {
  closeDeleteElementPopup,
} from '@SiteContainers/AssetsTable/actions'

import {
  openCreateLabelPopup,
  closePopupDashboardSidebar,
} from '@SiteContainers/DashboardSidebar/actions'

import './Dashboard.scss'

interface DashboardProps {
  readonly currentTab: string
  readonly email: string
  readonly confirmedEmail: boolean
  readonly hasCurrentSubscription: boolean
  readonly importSrtPopupOpen: boolean
  readonly exportationPopupOpen: boolean
  readonly deleteLabelPopupOpen: boolean
  readonly renameLabelPopupOpen: boolean
  readonly createLabelPopupOpen: boolean
  readonly rateSubtitlePopupOpen: boolean
  readonly externalTokenPopupOpen: boolean
  readonly deleteElementPopupOpen: boolean
  readonly deleteProjectVersionPopupOpen: boolean
  readonly loadingUserData: boolean
  readonly hasLoadedSubscriptionSuccessfully: boolean
  readonly userRole: string
  readonly loadingProjectVersions: boolean
  readonly projectVersions: IProjectVersion[]
  readonly selectedLabels: number[]
  closePopup(): void
  openCreateLabelPopup(): void
  closePopupDashboardSidebar(): void
  closeDeleteElementPopup(): void
  fetchProjectVersions(): void
}

interface DashboardState {
  hasLoadedProjectVersions: boolean
}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props)

    this.state = {
      hasLoadedProjectVersions: false
    }
  }

  componentDidMount() {
    this.props.fetchProjectVersions()

    const bodyElement = document.querySelectorAll('body')[0]
    bodyElement.style.backgroundColor = '#1F2023'

    if (window.location.href.includes('paid=true')) {
      showConfetti()
    }
  }

  componentWillUnmount() {
    const bodyElement = document.querySelectorAll('body')[0]
    bodyElement.style.backgroundColor = '#FFFFFF'
  }

  componentDidUpdate(prevProps: DashboardProps) {
    if (prevProps.loadingUserData && !this.props.loadingUserData) {
      if (this.props.confirmedEmail && !this.props.hasCurrentSubscription && this.props.userRole !== 'admin') {
        window.location.href = '/subscriptions'
      }
    }

    if (prevProps.loadingProjectVersions && !this.props.loadingProjectVersions) {
      this.setState({ hasLoadedProjectVersions: true })
    }
  }

  closePopup = () => {
    this.props.closePopup()
    this.props.closeDeleteElementPopup()
    this.props.closePopupDashboardSidebar()
  }

  forwardToProjectTypeSelection = () => {
    window.location.href = '/project_type_selection'
  }

  renderExternalTokenPopup = () => {
    return(
      <ExternalTokenPopup
        closePopup={this.closePopup}
      />
    )
  }

  renderExportationPopup = () => {
    return(
      <ExportationPopup
        closePopup={this.closePopup}
      />
    )
  }

  renderSrtImportPopup = () => {
    return(
      <ImportSrtPopup
        closePopup={this.closePopup}
      />
    )
  }

  renderDeleteProjectVersionPopup = () => {
    return(
      <DeleteProjectVersionPopup
        closePopup={this.closePopup}
      />
    )
  }

  renderRateSubtitlePopup = () => {
    return(
      <RateSubtitlePopup
        closePopup={this.closePopup}
      />
    )
  }

  renderCreateLabelPopup = () => {
    return(
      <CreateLabelPopup
        closePopup={this.closePopup}
      />
    )
  }

  renderDeleteElementPopup = () => {
    return(
      <DeleteElementPopup
        closePopup={this.closePopup}
      />
    )
  }

  renderDeleteLabelPopup = () => {
    return(
      <DeleteLabelPopup
        closePopup={this.closePopup}
      />
    )
  }

  renderRenameLabelPopup = () => {
    return (
      <RenameLabelPopup
        closePopup={this.closePopup}
      />
    )
  }

  renderPopups = () => {
    const {
      importSrtPopupOpen,
      exportationPopupOpen,
      createLabelPopupOpen,
      deleteLabelPopupOpen,
      renameLabelPopupOpen,
      rateSubtitlePopupOpen,
      externalTokenPopupOpen,
      deleteElementPopupOpen,
      deleteProjectVersionPopupOpen,
    } = this.props

    return(
      <React.Fragment>
        {importSrtPopupOpen && this.renderSrtImportPopup()}
        {exportationPopupOpen && this.renderExportationPopup()}
        {createLabelPopupOpen && this.renderCreateLabelPopup()}
        {rateSubtitlePopupOpen && this.renderRateSubtitlePopup()}
        {externalTokenPopupOpen && this.renderExternalTokenPopup()}
        {deleteProjectVersionPopupOpen && this.renderDeleteProjectVersionPopup()}
        {deleteElementPopupOpen && this.renderDeleteElementPopup()}
        {deleteLabelPopupOpen && this.renderDeleteLabelPopup()}
        {renameLabelPopupOpen && this.renderRenameLabelPopup()}
      </React.Fragment>
    )
  }

  renderProjectsTab = () => {
    return (
      <React.Fragment>
        <div className="Dashboard__main-header">
          <TableHeaderContainer
            openCreateLabelPopup={this.props.openCreateLabelPopup}
          />
        </div>

        <div className="Dashboard__main">
          <DashboardSidebar
            openCreateLabelPopup={this.props.openCreateLabelPopup}
          />

          <div className="Dashboard__right">
            <ProjectVersionsList
              openCreateLabelPopup={this.props.openCreateLabelPopup}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderChannelsTab = () => {
    return (
      <div className="Dashboard__right-channels">
        <ChannelsTable />
      </div>
    )
  }

  renderAssetsTab = () => {
    return (
      <div className="Dashboard__right-assets">
        <AssetsTable />
      </div>
    )
  }

  renderDashboard = () => {
    const shouldRenderProjectsTab = this.props.currentTab === 'projects'
    const shouldRenderChannelsTab = this.props.currentTab === 'channels'
    const shouldRenderAssetsTab = this.props.currentTab === 'assets'

    return (
      <div className="Dashboard">
        {this.renderPopups()}

        <Header />

        <div className="Dashboard__top">
          <p className="Dashboard__header-title">
            Dashboard
          </p>

          <div className="Dashboard__top-button">
            <ButtonNewV2
              size="large"
              styleType="brand-primary"
              caption="New project"
              onClick={this.forwardToProjectTypeSelection}
            />
          </div>
        </div>
        <DashboardMenu />

        {shouldRenderProjectsTab && this.renderProjectsTab()}
        {shouldRenderChannelsTab && this.renderChannelsTab()}
        {shouldRenderAssetsTab && this.renderAssetsTab()}
      </div>
    )
  }

  render() {
    const shouldForwardToEmailConfirmationPage = !this.props.confirmedEmail

    const shouldForwardToSubscriptions =
      !shouldForwardToEmailConfirmationPage &&
      this.props.hasLoadedSubscriptionSuccessfully &&
      !this.props.hasCurrentSubscription &&
      this.props.userRole !== 'admin'

    const hasProjectVersions = this.props.projectVersions.length > 0
    const hasLabelsSelected = this.props.selectedLabels.length > 0

    const shouldForwardToProjectTypeSelection =
      !shouldForwardToEmailConfirmationPage &&
      !shouldForwardToSubscriptions &&
      !this.props.loadingProjectVersions &&
      this.state.hasLoadedProjectVersions &&
      this.props.hasLoadedSubscriptionSuccessfully &&
      !hasLabelsSelected &&
      !hasProjectVersions

    const shouldForward = shouldForwardToEmailConfirmationPage || shouldForwardToSubscriptions || shouldForwardToProjectTypeSelection

    const shouldRenderLoader = !shouldForward && !this.state.hasLoadedProjectVersions
    const shouldRenderDashboard = !shouldForward && this.state.hasLoadedProjectVersions && (hasProjectVersions || hasLabelsSelected)

    return (
      <React.Fragment>
      {shouldForwardToEmailConfirmationPage && (
        <Navigate to="/onboarding/check_email" />
      )}
      {shouldForwardToSubscriptions && (
        <Navigate to="/subscriptions" />
      )}
      {shouldForwardToProjectTypeSelection && (
        <Navigate to="/project_type_selection" />
      )}
      {shouldRenderDashboard && this.renderDashboard()}
      {shouldRenderLoader && (<PageLoader />)}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const {
    projectVersionsList,
    dashboardSidebar,
    user,
    dashboardMenu,
    assetsTable,
    subscription,
    labels,
  } = state

  return {
    currentTab: dashboardMenu.currentTab,
    confirmedEmail: user.user.attributes.confirmedEmail,
    email: user.user.attributes.email,
    importSrtPopupOpen: projectVersionsList.openImportSrtPopup,
    deleteElementPopupOpen: assetsTable.openDeleteElementPopup,
    deleteLabelPopupOpen: dashboardSidebar.openDeleteLabelPopup,
    renameLabelPopupOpen: dashboardSidebar.openRenameLabelPopup,
    exportationPopupOpen: projectVersionsList.openExportationPopup,
    rateSubtitlePopupOpen: projectVersionsList.openRateSubtitlePopup,
    externalTokenPopupOpen: projectVersionsList.openExternalTokenPopup,
    createLabelPopupOpen: dashboardSidebar.openCreateLabelPopup,
    deleteProjectVersionPopupOpen: projectVersionsList.openDeleteProjectVersionPopup,
    hasCurrentSubscription: user.subscription.attributes.isCurrent,
    loadingUserData: user.loading,
    hasLoadedSubscriptionSuccessfully: subscription.hasLoadedSuccessfully,
    userRole: user.user.attributes.role,
    loadingProjectVersions: projectVersionsList.loading,
    projectVersions: projectVersionsList.projectVersions,
    selectedLabels: labels.selectedLabels,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    closePopup,
    openCreateLabelPopup,
    closeDeleteElementPopup,
    closePopupDashboardSidebar,
    fetchProjectVersions,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
