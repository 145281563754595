import React, { useState } from 'react'
import EditorButton from '@EditorComponents/EditorButton'
import { useForm } from 'react-hook-form'

import {
  IRenameLabelPayload,
} from '@SiteContainers/RenameLabelPopup/types'

import './EditLabelForm.scss'

interface EditLabelFormProps {
  readonly labelId: number
  readonly caption: string
  closePopup(): void
  renameLabel(payload: IRenameLabelPayload): void
}

type FormData = {
  readonly caption: string
}

const EditLabelForm: React.FC<EditLabelFormProps> = props => {
  const [newLabelCaption, setNewLabelCaption] = useState(props.caption)
  const { register, handleSubmit } = useForm<FormData>()

  const onSubmit = handleSubmit(({ caption }) => {
    const payload = { labelId: props.labelId, caption: caption.trim() }
    props.renameLabel(payload)
    props.closePopup()
  })

  const changeLabelName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewLabelCaption((e.target as HTMLInputElement).value)
  }

  const labelIsChanged = newLabelCaption.trim() !== props.caption.trim()
  const labelIsUnchanged = !labelIsChanged

  const shouldDisableButton = !newLabelCaption.trim() || labelIsUnchanged

  return(
    <div className="EditLabelForm">
      <div className="EditLabelForm__caption">
        Edit the label caption
      </div>
      <form
        className="EditLabelForm"
        onSubmit={onSubmit}
      >
        <input
          id="caption"
          type="text"
          value={newLabelCaption}
          placeholder="New label name"
          autoComplete="off"
          {...register('caption', { maxLength: 100, onChange: changeLabelName })}
        />

        <EditorButton
          type="submit"
          color="primary"
          caption="Edit"
          disabled={shouldDisableButton}
        />
      </form>
    </div>
  )
}

export default EditLabelForm
