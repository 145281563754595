import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PublicRoute: React.FC = () => {
  const isAuthorized = window.Checksub.cookies.get('authorized')

  if (isAuthorized) {
    return <Navigate to="/" replace />
  }

  return <Outlet />
}

export default PublicRoute