import React from 'react'
import DropdownList from 'react-widgets/DropdownList'
import classnames from 'classnames'

import './DubbingType.scss'

interface DubbingTypeProps {
  readonly selectedDubbingType: string
  setSelectedDubbingType(newDubbingType: string): void
}

const DUBBING_TYPES = ['Voice Cloning']

const DubbingType: React.FC<DubbingTypeProps> = props => {
  const disableDropdown = DUBBING_TYPES.length === 1

  return (
    <div
      className={classnames(
        'ProjectVersionSettingsForm__formfield DubbingType__formfield',
        {'noDropdown': disableDropdown})
      }
    >
      <label className="ProjectVersionSettingsForm__label DubbingType__label" htmlFor="dubbingType">
        Type of dubbing:
      </label>
        <DropdownList
          data={DUBBING_TYPES}
          id="dubbingType"
          name="dubbingType"
          value={props.selectedDubbingType}
          onChange={props.setSelectedDubbingType}
          disabled={disableDropdown}
        />
    </div>
  )
}

export default DubbingType
