import { IAction } from '@Root/types'

export const ProjectVersionSettingsActionTypes = {
  SUBMIT_PROJECT_VERSION_UPDATE: '@@projectVersionSettings/SUBMIT_PROJECT_VERSION_UPDATE',
  SUBMIT_PROJECT_VERSION_UPDATE_SUCCESS: '@@projectVersionSettings/SUBMIT_PROJECT_VERSION_UPDATE_SUCCESS',
  SUBMIT_PROJECT_VERSION_UPDATE_FAILURE: '@@projectVersionSettings/SUBMIT_PROJECT_VERSION_UPDATE_FAILURE',
  FETCH_PROJECT_VERSION_SETTINGS: '@@projectVersionSettings/FETCH_PROJECT_VERSION_SETTINGS',
  FETCH_PROJECT_VERSION_SETTINGS_SUCCESS: '@@projectVersionSettings/FETCH_PROJECT_VERSION_SETTINGS_SUCCESS',
  PROJECT_VERSION_SETTINGS_FAILURE: '@@projectVersionSettings/PROJECT_VERSION_SETTINGS_FAILURE',
  FETCH_PROJECT_VERSION_SETTINGS_MULTIPLE: '@@projectVersionSettings/FETCH_PROJECT_VERSION_SETTINGS_MULTIPLE',
  FETCH_PROJECT_VERSION_SETTINGS_MULTIPLE_SUCCESS: '@@projectVersionSettings/FETCH_PROJECT_VERSION_SETTINGS_MULTIPLE_SUCCESS',
  FETCH_PROJECT_VERSION_SETTINGS_DEMO: '@@projectVersionSettings/FETCH_PROJECT_VERSION_SETTINGS_DEMO',
  FETCH_PROJECT_VERSION_SETTINGS_DEMO_SUCCESS: '@@projectVersionSettings/FETCH_PROJECT_VERSION_SETTINGS_DEMO_SUCCESS',
}

export interface IProjectVersionSettings {
  readonly attributes: IProjectVersionSettingsStateAttributes
}

export type IProjectVersionSettingsStateAttributes = IProjectVersionSettingsAttributes & IOtherSettingsAttributes & ILanguageSettingsAttributes

export interface IProjectVersionSettingsAttributes {
  readonly title: string
  readonly language: string
  readonly duration: number
  readonly subtitleLanguages: string[]
  readonly hasMainAssets: boolean
  readonly hasAudioSeparation: boolean
  readonly subscriptionSubtitlesLength: number
  readonly projectTermbaseName?: string
}

export interface IOtherSettingsAttributes {
  readonly availableTotalCredits: number
}

export interface ILanguageSettingsAttributes extends ILanguageBaseSettingsAttributes {
  readonly alignmentLanguages: string[]
  readonly translationFormalityOptionLanguages: string[]
}

export interface ILanguageBaseSettingsAttributes {
  readonly languagesForVideos: string[]
  readonly translationLanguages: string[]
  readonly nonTranslatableLanguages: string[]
  readonly voiceCloningLanguages: string[]
}

export interface IProjectVersionSettingsState {
  readonly projectVersionSettings: IProjectVersionSettings
  readonly loading: boolean
  readonly isReadyForTheme: boolean
}

export interface PayloadSubmitProjectVersionUpdate {
  readonly payload: PayloadSubmitProjectVersionUpdateBody
}

export interface PayloadSubmitProjectVersionUpdateBody {
  readonly projectVersionLanguage: string
  readonly vocabularyIds: number[]
  readonly subtitleLanguages: string[]
  readonly automaticTranslation: boolean
  readonly projectVersionIds: string[]
  readonly includesTranscript: boolean
  readonly transcriptText?: string
  readonly srtTranscriptText?: string
  readonly reviewTranscriptBeforeTranslation: boolean
  readonly formality?: string
  readonly enableSpeakerDiarization: boolean
  readonly dubbing: boolean
  readonly generateShortenedProjectVersion: boolean
  readonly createCustomVoices?: boolean
  readonly shouldSeparateAudio?: boolean
  readonly adaptTranslation?: boolean
  readonly lipSyncModel?: string
  readonly captionGuidelineId?: number
  readonly translationGuidelineId?: number
  readonly termbaseId?: number
}

export interface IFetchProjectVersionSettingsPayload {
  readonly projectVersionId: string
}

export interface IProjectVersionSettingsFailureAction {
  readonly type: string
}

export interface IFetchProjectVersionSettingsSuccessPayload {
  attributes: IProjectVersionSettingsStateAttributes
}

export interface IFetchProjectVersionSettingsSuccessResponse {
  readonly data : {
    readonly projectVersionData: IProjectVersionDataBody
    readonly otherData: IOtherDataBody
    readonly languageData: ILanguageDataBody
  }
}

export interface IProjectVersionDataBody {
  readonly id: string
  readonly type: 'projectVersionSettings'
  readonly attributes: IProjectVersionSettingsAttributes
}

export interface IOtherDataBody {
  readonly id: string
  readonly type: 'otherSettings'
  readonly attributes: IOtherSettingsAttributes
}

export interface ILanguageDataBody {
  readonly id: string
  readonly type: 'languageSettings'
  readonly attributes: ILanguageSettingsAttributes
}

export interface ILanguageBaseDataBody {
  readonly id: string
  readonly type: 'languageSettings'
  readonly attributes: ILanguageBaseSettingsAttributes
}

export interface IOpenProjectVersionSettings {
  readonly type: string
  readonly projectVersionId: string
}

export interface ISubmitProjectVersionUpdateFailureAction {
  readonly type: string
}

export interface IFetchProjectVersionSettingsMultiplePayload {
  projectVersionIds: string[]
}

export interface IFetchProjectVersionSettingsMultipleSuccessResponse {
  readonly data : {
    readonly projectVersionData: IProjectVersionMultipleDataBody
    readonly otherData: IOtherDataBody
    readonly languageData: ILanguageDataBody
  }
}

export interface IProjectVersionMultipleDataBody {
  readonly attributes: IProjectVersionSettingsMultipleAttributes
}

export interface IFetchProjectVersionSettingsMultipleSuccessPayload {
  attributes: IProjectVersionSettingsMultipleStateAttributes
}

export type IProjectVersionSettingsMultipleStateAttributes = IProjectVersionSettingsMultipleAttributes & IOtherSettingsAttributes & ILanguageSettingsAttributes

export interface IProjectVersionSettingsMultipleAttributes {
  readonly duration: number
}

export interface IFetchProjectVersionSettingsDemoSuccessResponse {
  readonly data : {
    readonly languageData: ILanguageBaseDataBody
  }
}

export interface IFetchProjectVersionSettingsDemoSuccessPayload {
  attributes: ILanguageBaseSettingsAttributes
}

export type TProjectVersionSettingsAction = IOpenProjectVersionSettings | ISubmitProjectVersionUpdateFailureAction

export type IProjectVersionSettingsActionTypes =
  IAction<IFetchProjectVersionSettingsPayload> |
  IAction<IFetchProjectVersionSettingsSuccessPayload> |
  IAction<IFetchProjectVersionSettingsMultiplePayload> |
  IAction<IFetchProjectVersionSettingsMultipleSuccessPayload> |
  IAction<IFetchProjectVersionSettingsDemoSuccessPayload> |
  IProjectVersionSettingsFailureAction
