import React, { useState, useEffect } from 'react'
import Loader from '@SiteComponents/Loader'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import Close from '@Images/close_tiny.svg'
import classnames from 'classnames'

import {
  IExternalToken,
} from '@SiteContainers/ExternalTokenPopup/types'

import './ExternalTokenForm.scss'

const DEFAULT_VALIDITY_TIME_IN_DAYS = 30
const MINIMUM_VALIDITY_TIME_IN_DAYS = 1
const TEN_YEARS_IN_DAYS = 3650

interface ExternalTokenFormProps {
  readonly loading: boolean
  readonly externalTokenData: IExternalToken
  readonly textAreaRef: React.RefObject<HTMLTextAreaElement>
  readonly renderedFromDashboard: boolean
  copyToClipboard(e: any): void
  setValidityOfExternalToken(validityInDays: number): void
}

const ExternalTokenForm: React.FC<ExternalTokenFormProps> = props => {
  const {
    loading,
    textAreaRef,
    externalTokenData,
    renderedFromDashboard,
  } = props

  const {
    externalTokenUrl,
  } = externalTokenData.attributes

  const [hasDefinedValidity, setHasDefinedValidity] = useState(false)
  const [validity, setValidity] = useState(DEFAULT_VALIDITY_TIME_IN_DAYS.toString())

  useEffect(() => {
    setExpiryDateFarInTheFuture()
  }, [])

  const setExpiryDate = () => {
    props.setValidityOfExternalToken(DEFAULT_VALIDITY_TIME_IN_DAYS)
    setValidity(DEFAULT_VALIDITY_TIME_IN_DAYS.toString())
    setHasDefinedValidity(true)
  }

  const setExpiryDateFarInTheFuture = () => {
    props.setValidityOfExternalToken(TEN_YEARS_IN_DAYS)
    setValidity(TEN_YEARS_IN_DAYS.toString())
    setHasDefinedValidity(false)
  }

  const onChangeValidity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    if (target.value) {
      setValidity(target.value)
      const validityInDays = Math.max(Math.min(parseInt(target.value, 10), TEN_YEARS_IN_DAYS), MINIMUM_VALIDITY_TIME_IN_DAYS)
      props.setValidityOfExternalToken(validityInDays)
    } else {
      setValidity('')
      props.setValidityOfExternalToken(TEN_YEARS_IN_DAYS)
    }
  }

  return (
    <div className="ExternalTokenForm">
      <div className="ExternalTokenForm__title">Share your project</div>
      {externalTokenUrl && (
        <React.Fragment>
          <textarea
            className="ExternalTokenForm__url"
            ref={textAreaRef}
            value={externalTokenUrl}
            readOnly
          />

          <div className="ExternalTokenForm__expiry_date_wrapper">
            {hasDefinedValidity ? (
              <div
              className={classnames(
                'ExternalTokenForm__expiry_date',
                {'ExternalTokenForm__expiry_date-dashboard': renderedFromDashboard},
              )}
              >
                Access expires in
                <span className="ExternalTokenForm__input-wrapper">
                  <input
                    type="number"
                    name="validity"
                    min="1"
                    max="3650"
                    value={validity}
                    onChange={onChangeValidity}
                    className="ExternalTokenForm__input"
                  />
                </span>
                days
                <Close onClick={setExpiryDateFarInTheFuture} />
              </div>
            ) : (
              <div
                className={classnames(
                  'ExternalTokenForm__add_expiry_date',
                  {'ExternalTokenForm__add_expiry_date-dashboard': renderedFromDashboard},
                )}
                onClick={setExpiryDate}
              >
                Add expiry date
              </div>
            )}
          </div>

          <div>
            {loading ? (
              <Loader color="dark" />
            ) : (
              <ButtonNewV2
                styleType="brand-primary"
                size="large"
                caption="Copy link"
                onClick={props.copyToClipboard}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default ExternalTokenForm
