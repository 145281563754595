import React from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { IUser, Submittable } from '@Root/types'

import './PasswordNewForm.scss'

interface PasswordNewFormProps extends Submittable {
}

type FormData = Pick<IUser, 'email'>

const PasswordNewForm: React.FC<PasswordNewFormProps> = props => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>()
  const onSubmit = handleSubmit(({ email }) => {
    const user = { email }
    props.submit({ user })
  })

  const renderEmail = () => {
    return(
      <div className="PasswordNewForm__formfield ">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          {...register('email', {
            required: true,
            maxLength: 100,
            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,63})+$/ // https://www.w3resource.com/javascript/form/email-validation.php. Changed to \w{1,63} to allow all possible TLD (see http://data.iana.org/TLD/tlds-alpha-by-domain.txt)
          })}
        />
        {
          errors.email &&
          errors.email.type === 'required' &&
          <span className="errorMessage">This field is required</span>
        }
        {
          errors.email &&
          errors.email.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
        {
          errors.email &&
          errors.email.type === 'pattern' &&
          <span className="errorMessage">This must be a valid email address</span>
        }
      </div>
    )
  }

  const renderSubmitButton = () => {
    return(
      <ButtonNewV2
        type="submit"
        caption="Send me reset password instructions"
        size="large"
        styleType="brand-primary"
        showSpinner={props.showSpinner}
        disabled={props.disableButton}
      />
    )
  }

  const renderLinks = () => {
    return(
      <div className="PasswordNewForm__links">
        <Link to="/users/sign_in">Log in</Link>{' '}|{' '}
        <Link to="/users/sign_up">Create a new account</Link><br />
        <Link to="/users/confirmation/new">Didn't receive confirmation instructions?</Link>
      </div>
    )
  }

  return(
    <form className="PasswordNewForm" onSubmit={onSubmit} >
      {renderEmail()}
      {renderSubmitButton()}
      {renderLinks()}
    </form>
  )
}

export default PasswordNewForm
