import React from 'react'
import router from '@Site/Router'

import './ConnectChannelButton.scss'

interface ConnectChannelButtonProps {}

const ConnectChannelButton: React.FC<ConnectChannelButtonProps> = _props => {
  const onClick = () => {
    router.navigate('/channels/new')
  }

  return (
    <div
      className="ConnectChannelButton"
      onClick={onClick}
    >
      + Connect a Channel
    </div>
  )
}

export default ConnectChannelButton
