import React, { useEffect, useRef } from 'react'
import uniq from 'lodash/uniq'
import Multiselect from 'react-widgets/Multiselect'
import DropdownList from 'react-widgets/DropdownList'
import ProjectType from './ProjectType'
import classnames from 'classnames'
import { Controller, Control, FieldErrors, ControllerRenderProps, UseFormSetValue } from 'react-hook-form'

import './MainSettings.scss'

type TFormData = {
  subtitleLanguages: string[]
  projectVersionLanguage: string
}

interface MainSettingsProps {
  readonly errors: FieldErrors<TFormData>
  readonly control: Control<TFormData, any>
  readonly setValue: UseFormSetValue<TFormData>
  readonly projectVersionLanguage: string
  readonly subtitleLanguages: string[]
  readonly isTranslatable: boolean
  readonly initialProjectVersionLanguage: string
  readonly languagesForVideos: string[]
  readonly translationLanguages: string[]
  readonly disabledSubtitleLanguages: string[]
  readonly hasLoaded: boolean
  readonly projectType: string
  readonly projectTypeOptions: string[]
  changeSubtitleLanguages(newSubtitleLanguages: string[]): void
  changeProjectVersionLanguage(newProjectVersionLanguage: string): void
  changeProjectType(newProjectType: string): void
}

const MainSettings: React.FC<MainSettingsProps> = props => {
  const {
    errors,
    control,
    projectVersionLanguage,
    changeSubtitleLanguages,
    subtitleLanguages,
    isTranslatable,
    languagesForVideos,
    changeProjectVersionLanguage,
    translationLanguages,
    initialProjectVersionLanguage,
    disabledSubtitleLanguages,
    hasLoaded,
    projectType,
    changeProjectType,
    projectTypeOptions,
  } = props

  const subtitleLanguagesRef = useRef(subtitleLanguages)

  const TOP_LANGUAGES = [
    'Arabic',
    'Chinese, Mandarin (Simplified, China)',
    'English',
    'French',
    'French (Canada)',
    'German',
    'Hindi',
    'Italian',
    'Japanese',
    'Portuguese',
    'Russian',
    'Spanish'
  ]

  let originalLanguages
  let subtitlingLanguages
  let topLanguagesForSubtitling = TOP_LANGUAGES

  const setToManual = (languages: string[]) => languages.map((language) => {
    if (language.endsWith('(manual)')) { return language }

    return language + ' (manual)'
  })

  if (isTranslatable) {
    originalLanguages = languagesForVideos
    subtitlingLanguages = translationLanguages
  } else {
    originalLanguages = languagesForVideos
    subtitlingLanguages = setToManual(translationLanguages)
    topLanguagesForSubtitling = setToManual(topLanguagesForSubtitling)
  }

  const originalLanguagesWithTopLanguagesFirst = uniq(TOP_LANGUAGES.concat(originalLanguages))
  const subtitlingLanguagesWithTopLanguagesFirst = uniq(topLanguagesForSubtitling.concat(subtitlingLanguages))

  const originalLanguageGrouping = (language: string) => TOP_LANGUAGES.includes(language) ? 'Top Languages' : 'All Languages'
  const subtitlingLanguageGrouping = (language: string) => topLanguagesForSubtitling.includes(language) ? 'Top Languages' : 'All Languages'

  const shouldDisableSelectingProjectVersionLanguage = !!initialProjectVersionLanguage

  const fillInPlaceholderProjectVersionLanguage = () => {
    const searchProjectVersionLanguage = document.querySelectorAll('.MainSettings__formfield-projectVersionLanguage .rw-multiselect-input')[0] as HTMLInputElement

    if (searchProjectVersionLanguage) {
      searchProjectVersionLanguage.placeholder = 'Set language...'
    }
  }

  useEffect(() => {
    // Add button behaviour, i.e. opening and closing dropdown when clicking on the input field
    const inputContainerSubtitleLanguages = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages .rw-widget-input.rw-widget-picker.rw-widget-container')[0] as HTMLElement
    inputContainerSubtitleLanguages.addEventListener('click', onClick)
  }, [])

  const onClick = () => {
    if (subtitleLanguagesRef.current.length > 0) { return }

    const closedDropdownSubtitleLanguages = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages .rw-popup-transition-exited')[0] as HTMLElement
    const dropdownClosed = !!closedDropdownSubtitleLanguages
    if (dropdownClosed) {
      closedDropdownSubtitleLanguages.classList.remove('rw-popup-transition-exited')
    } else {
      const dropdownSubtitleLanguages = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages .rw-popup-container')[0] as HTMLElement
      if (!!dropdownSubtitleLanguages) {
        dropdownSubtitleLanguages.classList.add('rw-popup-transition-exited')
      }
    }
  }

  useEffect(() => {
    props.setValue('projectVersionLanguage', projectVersionLanguage)

    const inputFieldProjectVersionLanguage = document.querySelectorAll('.MainSettings__formfield-projectVersionLanguage .rw-widget-input.rw-widget-picker.rw-widget-container')[0] as HTMLElement
    if (!!projectVersionLanguage && !initialProjectVersionLanguage) {
      inputFieldProjectVersionLanguage.style.backgroundColor = '#7318FD'
      inputFieldProjectVersionLanguage.style.border = 'none'
    }
  }, [projectVersionLanguage])

  useEffect(() => {
    subtitleLanguagesRef.current = subtitleLanguages
    props.setValue('subtitleLanguages', subtitleLanguages)

    setTimeout(() => {
      const inputTagListSubtitleLanguages = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages .rw-multiselect-taglist')[0] as HTMLInputElement
      const inputFieldSubtitleLanguages = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages .rw-multiselect-input')[0] as HTMLInputElement
      const inputContainerSubtitleLanguages = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages .rw-widget-input.rw-widget-picker.rw-widget-container')[0] as HTMLElement
      if (subtitleLanguages.length > 0) {
        inputTagListSubtitleLanguages.style.width = '537px'
        inputFieldSubtitleLanguages.style.paddingLeft = '10px'
        inputFieldSubtitleLanguages.style.paddingTop = '5px'
        inputFieldSubtitleLanguages.style.height = '24px'
        inputFieldSubtitleLanguages.placeholder = '+ Add language'
        inputContainerSubtitleLanguages.style.backgroundColor = '#2D2F36'
        inputContainerSubtitleLanguages.style.border = 'none'
        inputContainerSubtitleLanguages.style.paddingLeft = '0px'
      } else {
        inputTagListSubtitleLanguages.style.width = '100%'
        inputFieldSubtitleLanguages.style.paddingLeft = '0'
        inputFieldSubtitleLanguages.style.paddingTop = '0'
        inputFieldSubtitleLanguages.style.height = '17px'
        inputContainerSubtitleLanguages.style.backgroundColor = '#1F2433'
        inputContainerSubtitleLanguages.style.border = '1px solid #8A8F98'
        inputContainerSubtitleLanguages.style.padding = '10px 12px'
      }
    }, 0) // setTimeout is needed to make sure the DOM is fully loaded, otherwise the querySelector can return null
  }, [subtitleLanguages])

  const hasNoDropdown = !!initialProjectVersionLanguage || !hasLoaded

  const onSearch = () => {
    setTimeout(() => {
      const emptyList = document.getElementsByClassName('rw-list-empty')

      if (emptyList && emptyList.length > 0) {
        emptyList[0].innerHTML = 'Not available'
      }
    }, 0)
  }

  const handleProjectVersionLanguageChange = (event: any) => {
    changeProjectVersionLanguage(event.target.value as string)
  }

  const handleSubtitleLanguagesChange = (event: any) => {
    changeSubtitleLanguages(event.target.value as string[])
  }

  const renderProjectVersionLanguageDropDownDropdownList = ({ field }: { field: ControllerRenderProps<TFormData, 'projectVersionLanguage'> }) => {
    return (
      <DropdownList
        id="projectVersionLanguage"
        data={originalLanguagesWithTopLanguagesFirst}
        filter="startsWith"
        selectIcon={false}
        value={field.value}
        groupBy={originalLanguageGrouping}
        onChange={field.onChange}
        placeholder="Select the video language..."
        onSearch={onSearch}
        disabled={shouldDisableSelectingProjectVersionLanguage}
      />
    )
  }

  const renderProjectVersionLanguageDropDown = () => {
    return(
      <div className={classnames('ProjectVersionSettingsForm__formfield MainSettings__formfield-projectVersionLanguage', {'noDropdown': hasNoDropdown})} onClick={fillInPlaceholderProjectVersionLanguage}>
        <label className="ProjectVersionSettingsForm__label" htmlFor="projectVersionLanguage">
          Original language of the video(s):
        </label>

        <Controller
          control={control}
          name="projectVersionLanguage"
          rules={{ required: true, onChange: handleProjectVersionLanguageChange }}
          render={renderProjectVersionLanguageDropDownDropdownList}
        />
        {errors.projectVersionLanguage && (
          <span className="errorMessage">This field is required</span>
        )}
      </div>
    )
  }

  const renderSubtitleLanguagesDropDownDropdownList = ({ field }: { field: ControllerRenderProps<TFormData, 'subtitleLanguages'> }) => {
    return (
      <Multiselect
        id="subtitleLanguages"
        data={subtitlingLanguagesWithTopLanguagesFirst}
        filter="contains"
        selectIcon={false}
        value={field.value}
        groupBy={subtitlingLanguageGrouping}
        onChange={field.onChange}
        placeholder="Choose subtitle languages..."
        onSearch={onSearch}
        disabled={hasLoaded ? disabledSubtitleLanguages : true}
      />
    )
  }

  const renderSubtitleLanguagesDropDown = () => {
    return(
      <div className={classnames('ProjectVersionSettingsForm__formfield MainSettings__formfield-subtitleLanguages', {'MainSettings__formfield-subtitleLanguages-filledOut': subtitleLanguages.length > 0})}>
        <label className="ProjectVersionSettingsForm__label MainSettings__formfield-subtitleLanguages-label" htmlFor="subtitleLanguages">
          Desired languages:
        </label>
        <Controller
          control={control}
          name="subtitleLanguages"
          rules={{ required: true, onChange: handleSubtitleLanguagesChange }}
          render={renderSubtitleLanguagesDropDownDropdownList}
        />
        {errors.subtitleLanguages && (
          <span className="errorMessage">This field is required</span>
        )}
      </div>
    )
  }

  const renderProjectType = () => {
    return(
      <ProjectType
        projectType={projectType}
        changeProjectType={changeProjectType}
        projectTypeOptions={projectTypeOptions}
      />
    )
  }

  return (
    <React.Fragment>
      {renderProjectVersionLanguageDropDown()}
      {renderSubtitleLanguagesDropDown()}
      {renderProjectType()}
    </React.Fragment>
  )
}

export default MainSettings
