import React from 'react'
import DropdownList from 'react-widgets/DropdownList'
import classnames from 'classnames'

import './ProjectType.scss'

interface ProjectTypeProps {
  readonly projectType: string
  readonly projectTypeOptions: string[]
  changeProjectType(newProjectType: string | undefined): void
}

const ProjectType: React.FC<ProjectTypeProps> = props => {
  const {
    projectType,
    changeProjectType,
    projectTypeOptions,
  } = props

  const noDropdown = projectTypeOptions.length === 1

  return (
    <div
      className={classnames(
        'ProjectVersionSettingsForm__formfield ProjectType__formfield',
        {'noDropdown': noDropdown})
      }
    >
      <label className="ProjectVersionSettingsForm__label" htmlFor="projectType">
        Type of project:
      </label>

      <div className="ProjectType__dropdownlist-wrapper">
        <DropdownList
          data={projectTypeOptions}
          id="projectType"
          name="projectType"
          value={projectType}
          onChange={changeProjectType}
          disabled={noDropdown}
        />
      </div>
    </div>
  )
}

export default ProjectType
