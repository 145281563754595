import React from 'react'
import CloseIconForLightMode from '@Images/close.svg'
import CloseIconForDarkMode from '@Images/close_dark.svg'

import './Popup.scss'

interface PopupProps {
  readonly isDark?: boolean
  readonly className?: string
  readonly children?: React.ReactNode
  closePopup?(): void
}

const Popup: React.FC<PopupProps> = props => {
  const renderCloseIcon = () => {
    return (
      <React.Fragment>
        {props.isDark ? (
          <CloseIconForDarkMode
            className="Popup__close Popup__close-dark"
            onClick={props.closePopup}
            />
          ) : (
            <CloseIconForLightMode
            className="Popup__close"
            onClick={props.closePopup}
          />
        )}
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <div className="Overlay" />
      <div className={`Popup ${props.className}`}>
        {props.closePopup && renderCloseIcon()}
        {props.children}
      </div>
    </React.Fragment>
  )
}

export default Popup
