import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Popup from '@SiteComponents/Popup'
import Button from '@SiteComponents/Button'
import Loader from '@SiteComponents/Loader'

import {
  deleteElement
} from '@SiteContainers/AssetsTable/actions'

import {
  IDeleteElementPayload
} from '@SiteContainers/AssetsTable/types'

import {
  IProjectVersion
} from '@SiteContainers/ProjectVersionsList/types'

import AttentionIcon from '@Images/attention.svg'

import './DeleteElementPopup.scss'

const NUMBER_OF_PROJECT_VERSIONS_TO_SHOW = 3

interface DeleteElementProps {
  readonly loadingAffectedProjectVersions: boolean
  readonly deleting: boolean
  readonly elementId: string
  readonly affectedProjectVersions: IProjectVersion[]
  closePopup(): void
  deleteElement(payload: IDeleteElementPayload): void
}

interface DeleteElementState {
  readonly deleteConfirmation: string
}

class DeleteElement extends React.Component<DeleteElementProps, DeleteElementState> {
  state = {
    deleteConfirmation: ''
  }

  changeDeleteCofirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      deleteConfirmation: (e.target as HTMLInputElement).value
    })
  }

  handleConfirm = () => {
    const canDelete = this.state.deleteConfirmation === 'delete' || this.state.deleteConfirmation === 'DELETE'

    if (canDelete) {
      this.props.deleteElement({ elementId: this.props.elementId })
    }
  }

  projectVersionsToShow = this.props.affectedProjectVersions.slice(0, NUMBER_OF_PROJECT_VERSIONS_TO_SHOW)

  projectVersionsList = this.projectVersionsToShow.map((projectVersion) => {
    return (
      <li
        key={projectVersion.id}
        className="DeleteElementPopup__projectVersions-list-item"
      >
        <p>
          {`- ${projectVersion.attributes.title}`}
        </p>
      </li>
    )
  })

  renderLoader = () => {
    return (
      <Loader color="dark" />
    )
  }

  renderPopupContent = () => {
    const {
      deleting,
      affectedProjectVersions,
    } = this.props

    const { deleteConfirmation } = this.state
    const canDelete = this.state.deleteConfirmation === 'delete' || this.state.deleteConfirmation === 'DELETE'
    const shouldDisableButton = !canDelete || deleting
    const shouldShowWarningAboutProjectVersions = affectedProjectVersions.length > 0
    const shouldRenderInformationAboutMoreProjectVersions = affectedProjectVersions.length > NUMBER_OF_PROJECT_VERSIONS_TO_SHOW

    return (
      <React.Fragment>
        <p className="DeleteElementPopup__description">
          <AttentionIcon className="DeleteElementPopup__description__icon" />
          Warning: The asset will be deleted permanently.<br />
        </p>
        {shouldShowWarningAboutProjectVersions && (
          <React.Fragment>
            <p className="DeleteElementPopup__description">
              In addition, it will be removed from the following project(s):
            </p>
            <ul className="DeleteElementPopup__projectVersions-list">
              {this.projectVersionsList}
              { shouldRenderInformationAboutMoreProjectVersions && (
                <li className="DeleteElementPopup__projectVersions-list-item">
                  {`and from ${affectedProjectVersions.length - NUMBER_OF_PROJECT_VERSIONS_TO_SHOW} more projects`}
                </li>
              )}
            </ul>
          </React.Fragment>
        )}
        <p className="DeleteElementPopup__description">
          Type “delete” below to validate this action.
        </p>

        <input className="DeleteElementPopup__confirmation"
          placeholder="delete"
          value={deleteConfirmation}
          onChange={this.changeDeleteCofirmation}
        />

        <Button
          color="primary-dark"
          caption="Confirm the action"
          onClick={this.handleConfirm}
          showSpinner={deleting}
          disabled={shouldDisableButton}
        />
      </React.Fragment>
    )
  }

  render() {
    const {
      closePopup,
      loadingAffectedProjectVersions
    } = this.props

    const shouldRenderLoader = loadingAffectedProjectVersions

    return (
      <Popup
        className="DeleteElementPopup"
        closePopup={closePopup}
        isDark
      >
        <div className="DeleteElementPopup__title">Delete the asset</div>
        {shouldRenderLoader ? this.renderLoader() : this.renderPopupContent()}
      </Popup>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { assetsTable } = state

  return {
    deleting: assetsTable.deleting,
    elementId: assetsTable.elementToDeleteId,
    affectedProjectVersions: assetsTable.affectedProjectVersions,
    loadingAffectedProjectVersions: assetsTable.loadingAffectedProjectVersions
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    deleteElement,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteElement)
