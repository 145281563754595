import React from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import { IPasswordable, Submittable } from '@Root/types'
import { useForm } from 'react-hook-form'

import './UsersPasswordEditForm.scss'

interface UsersPasswordEditFormProps extends Submittable {
}

type FormData = IPasswordable

const UsersPasswordEditForm: React.FC<UsersPasswordEditFormProps> = props => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormData>()
  // tslint:disable-next-line:no-shadowed-variable
  const onSubmit = handleSubmit(({ password, passwordConfirmation }) => {
    //  TODO: fix that later... (password_confirmation --> passwordConfirmation)
    const params = new URLSearchParams(window.location.search)
    const resetPasswordToken = params.get('reset_password_token')

    const user = {
      password,
      password_confirmation: passwordConfirmation,
      reset_password_token: resetPasswordToken
    }

    props.submit({ user })
  })

  const password = watch('password')
  const passwordConfirmation = watch('passwordConfirmation')

  const renderPassword = () => {
    return(
      <div className="UsersPasswordEditForm__formfield">
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          {...register('password', {
            required: true,
            minLength: 6,
            maxLength: 100,
            validate: (value: string) => value === passwordConfirmation
          })}
        />
        {
          errors.password &&
          errors.password.type === 'required' &&
          <span className="errorMessage">This field is required</span>
        }
        {
          errors.password &&
          errors.password.type === 'minLength' &&
          <span  className="errorMessage">Password must be at least 6 characters long</span>
        }
        {
          errors.password &&
          errors.password.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
        {
          errors.password &&
          errors.password.type === 'validate' &&
          <span  className="errorMessage">Password and confirmed password must match</span>
        }
      </div>
    )
  }

  const renderPasswordConfirmation = () => {
    return(
      <div className="UsersPasswordEditForm__formfield">
        <label htmlFor="passwordConfirmation">Confirm the password</label>
        <input
          id="passwordConfirmation"
          type="password"
          {...register('passwordConfirmation', {
            required: true,
            maxLength: 100,
            validate: (value: string) => value === password
          })}
        />
        {
          errors.passwordConfirmation &&
          errors.passwordConfirmation.type === 'required' &&
          <span className="errorMessage">This field is required</span>
        }
        {
          errors.passwordConfirmation &&
          errors.passwordConfirmation.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
        {
          errors.passwordConfirmation &&
          errors.passwordConfirmation.type === 'validate' &&
          <span  className="errorMessage">Password and confirmed password must match</span>
        }
      </div>
    )
  }

  const renderSubmitButton = () => {
    return(
      <ButtonNewV2
        type="submit"
        caption="Change my password"
        size="large"
        styleType="brand-primary"
        showSpinner={props.showSpinner}
        disabled={props.disableButton}
      />
    )
  }

  return(
    <form className="UsersPasswordEditForm" onSubmit={onSubmit} >
      {renderPassword()}
      {renderPasswordConfirmation()}
      {renderSubmitButton()}
    </form>
  )
}

export default UsersPasswordEditForm
